import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import moment from "moment-timezone";
import mainLogo from "../assets/images/logoImage.png";
import iconTrash from "../assets/images/iconTrash.png";
import { Row, Col } from "react-bootstrap";

const Lobby = (props) => {
  const [games, setGames] = useState([]);
  const [newGameName, setNewGameName] = useState("");
  const [newGameNumber, setNewGameNumber] = useState(4);

  const getGames = async () => {
    const gamesRef = props.db.collection("games");
    const snapshot = await gamesRef.get();
    snapshot.forEach((doc) => {
      let gameDetails = doc.data();
      gameDetails.id = doc.id;
      setGames((oldArray) => [...oldArray, gameDetails]);
    });
  };

  const handleNewGameName = (e) => {
    setNewGameName(e.target.value);
  };

  const handleNewGameNumber = (e) => {
    setNewGameNumber(e.target.value);
  };

  const handleNewGameSubmit = (e) => {
    e.preventDefault();
    if (newGameName && newGameNumber) {
      let date = new Date();

      props.db
        .collection("games")
        .add({
          name: newGameName,
          numberOfPlayers: newGameNumber,
          created: date.toString(),
          mode: "setup",
          started: false,
          playerData: {},
        })
        .then((data) => {
          window.location.href = data.id;
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    }
  };

  const handleDelete = (id) => {
    props.db
      .collection("games")
      .doc(id)
      .delete()
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getGames();
  }, []);

  return (
    <div className="lobby">
      <Row>
        <Col xl={4}>
          <Row>
            <Col>
              <div className="mainLogo">
                <img src={mainLogo} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Start New Game</h2>
              <form onSubmit={handleNewGameSubmit}>
                <div className="inputContainer">
                  <input
                    onChange={handleNewGameName}
                    type="text"
                    name="gameName"
                    placeholder="Name of your game"
                    value={newGameName}
                  />
                </div>
                <div className="numberOfPlayersContainer">
                  <label htmlFor="numberOfPlayers">Players:</label>
                  <select
                    id="numberOfPlayers"
                    name="numberOfPlayers"
                    onChange={handleNewGameNumber}
                  >
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option selected value="4">
                      4
                    </option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                </div>
                <button className="button primary" type="submit">
                  Submit
                </button>
              </form>
            </Col>
          </Row>
        </Col>
        <Col>
          {" "}
          <table className="lobbyTable">
            <tr className="topRow">
              <th>Name</th>
              <th>Date Created</th>
              <th></th>
              <th></th>
            </tr>
            {games.map((game, index) => {
              var tz = moment.tz.guess();
              return (
                <tr key={index}>
                  <td>{game.name}</td>
                  <td className="date">
                    <Moment format="LLL z" date={game.created} tz={tz} />
                  </td>
                  <td>
                    <a href={game.id}>
                      <div className="button primary">Launch Game</div>
                    </a>
                  </td>
                  <td>
                    <div
                      className="delete"
                      onClick={() => {
                        handleDelete(game.id);
                      }}
                    >
                      <img src={iconTrash} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </table>
        </Col>
      </Row>
    </div>
  );
};

export default Lobby;
