import React from "react";
import * as config from "../config";
import iconMeat from "../assets/images/icon-meat.png";
import iconChoi from "../assets/images/icon-choi.png";
import iconMixed from "../assets/images/icon-mixed.png";
import iconTea from "../assets/images/icon-tea.png";
import iconTofu from "../assets/images/icon-tofu.png";
import iconGate from "../assets/images/gate.png";

const Plant = (props) => {
  const plant = props.data;
  const images = require.context("../assets/images", true);

  let type = config.powerPlants[plant].type;
  let typeIcon;
  if (type == "coal") {
    typeIcon = iconMeat;
  } else if (type == "oil") {
    typeIcon = iconChoi;
  } else if (type == "hybrid") {
    typeIcon = iconMixed;
  } else if (type == "garbage") {
    typeIcon = iconTea;
  } else if (type == "uranium") {
    typeIcon = iconTofu;
  }
  let clickable = "";
  let dataTip = "These will be up for bid later in the game";
  let dataFor = "inactive";

  if (!props.disabled) {
    clickable = " clickable";
    dataTip = "Click to select partner";
    dataFor = "encourage";
  }

  let combinedClass = "plant plant" + plant + clickable;

  return (
    <div
      className={combinedClass}
      onClick={() => {
        if (!props.disabled) {
          props.handleSelect(plant);
        }
      }}
      data-tip={dataTip}
      data-for={dataFor}
    >
      <div className="topbar">
        <div className="cost">NT${config.powerPlants[plant].cost}</div>
        <div className="resourceContainer">
          {[...Array(config.powerPlants[plant].resourcesNeeded)].map((e, i) => (
            <img className="resourceIcons" key={i} src={typeIcon} />
          ))}
        </div>
      </div>

      <div className="foodName">
        <span>{config.powerPlants[plant].name}</span>
      </div>
      <div className="partner">
        {config.powerPlants[plant].partner && (
          <img
            src={
              images("./partner-" + config.powerPlants[plant].partner + ".jpeg")
                .default
            }
          />
        )}
      </div>
      <div className="locations">
        <img src={iconGate} /> {config.powerPlants[plant].citiesPowered}
      </div>
    </div>
  );
};

export default Plant;
