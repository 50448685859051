import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import FadeIn from "react-fade-in";
import * as tools from "../functions";

const Winner = (props) => {
  const data = props.data;
  const [showWinners, setShowWinners] = useState(false);
  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    //event.target.pauseVideo();
    setShowWinners(true);
  };
  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  useEffect(() => {
    // set steps
  }, [props]);

  let winnersList = tools.getWinners(data);
  let winnersArray = [];
  for (var i = 0; i < winnersList.length; i++) {
    let placeText;
    let winnerClass;
    switch (i) {
      case 0:
        placeText = "1st";
        winnerClass = "place firstPlace";
        break;
      case 1:
        placeText = "2nd";
        winnerClass = "place secondPlace";
        break;
      case 2:
        placeText = "3rd";
        winnerClass = "place";
        break;
      case 3:
        placeText = "4th";
        winnerClass = "place";
        break;
      case 4:
        placeText = "5th";
        winnerClass = "place";
        break;
      case 5:
        placeText = "6th";
        winnerClass = "place";
        break;
    }
    winnersArray.push({
      name: winnersList[i],
      winnerClass: winnerClass,
      placeText: placeText,
    });
  }

  winnersArray.reverse();

  return (
    <div className="winnerBox">
      {showWinners && (
        <FadeIn className="scoreBox" delay={3000}>
          {winnersArray.map((winner) => {
            return (
              <div className={winner.winnerClass}>
                {winner.placeText}: {winner.name}
              </div>
            );
          })}
        </FadeIn>
      )}
      <YouTube
        videoId="WtASoOcub2k"
        opts={opts}
        className="video"
        onReady={_onReady}
      />
      ;
    </div>
  );
};

export default Winner;
