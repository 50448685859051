import logo from "./logo.svg";
import "./App.scss";
import "./Plants.scss";
import "./Bootstrap.min.css";
import firebase from "./Firebase/Firestore";
import Lobby from "./Pages/Lobby";
import Game from "./Pages/Game";
import HttpsRedirect from "react-https-redirect";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const App = () => {
  const db = firebase.firestore();

  return (
    <HttpsRedirect>
      <Router>
        <Switch>
          <Route path="/:gameId" children={<Game db={db} />}></Route>
          <Route path="/">
            <div className="App">
              <Lobby db={db} />
            </div>
          </Route>
        </Switch>
      </Router>
    </HttpsRedirect>
  );
};

export default App;
