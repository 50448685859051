import _ from "lodash";
import * as config from "./config";

export const shuffle = (array) => {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const setupStep3Pps = (data) => {
  let currentMarketTemp = _.cloneDeep(data.currentMarket);
  let futureMarketTemp = _.cloneDeep(data.futureMarket);
  let result = {};
  // pool the cards
  // pool the new card and existing cards and re-order them
  let newPool = [];

  for (var i = 0; i < currentMarketTemp.length; i++) {
    newPool.push(currentMarketTemp[i]);
  }
  for (var i = 0; i < futureMarketTemp.length; i++) {
    newPool.push(futureMarketTemp[i]);
  }
  // sort them
  newPool.sort((a, b) => a - b);

  // remove the lowest numbered card
  newPool.shift();

  result.currentMarket = [];
  result.futureMarket = [];
  result.ppDeck = [];

  // add all 6 into current market
  for (var i = 0; i < 6; i++) {
    if (newPool[i]) {
      result.currentMarket.push(newPool[i]);
    }
  }
  return result;
};

export const updatePowerPlantsByHighestCity = (data, highestCities) => {
  let currentMarket = _.cloneDeep(data.currentMarket);
  let futureMarket = _.cloneDeep(data.futureMarket);
  let ppDeck = _.cloneDeep(data.ppDeck);
  let step3Market = _.cloneDeep(data.step3Market);
  let newStage = data.stage;
  let result = {};

  // go through each current market and remove the power plants with the smallest power plants
  currentMarket.forEach((pp) => {
    if (config.powerPlants[pp].cost <= highestCities) {
      let updatedPps;

      // first remove
      if (data.stage < 3) {
        updatedPps = removePowerplant(
          pp,
          currentMarket,
          futureMarket,
          ppDeck,
          step3Market
        );
      } else if (data.stage == 3) {
        updatedPps = removePowerplantStep3(
          pp,
          currentMarket,
          futureMarket,
          ppDeck,
          step3Market
        );
      }
      currentMarket = updatedPps.currentMarket;
      futureMarket = updatedPps.futureMarket;
      ppDeck = updatedPps.ppDeck;
      step3Market = updatedPps.step3Market;
    }
  });

  // go through all the ones in the ppDeck and remove the power plants below the max cities
  ppDeck.filter((pp) => config.powerPlants[pp].cost <= highestCities);

  result = {
    currentMarket: currentMarket,
    futureMarket: futureMarket,
    ppDeck: ppDeck,
    step3Market: step3Market,
    stage: newStage,
  };

  return result;
};

export const updatePowerPlants = (data) => {
  let currentMarket = _.cloneDeep(data.currentMarket);
  let futureMarket = _.cloneDeep(data.futureMarket);
  let ppDeck = _.cloneDeep(data.ppDeck);
  let step3Market = _.cloneDeep(data.step3Market);
  let result = {};
  let newStage = data.stage;
  let usersArray = Object.keys(data.playerData);
  let highestNumberOfCities = 0;

  usersArray.forEach((player) => {
    if (data.playerData[player].cities.length > highestNumberOfCities) {
      highestNumberOfCities = data.playerData[player].cities.length;
    }
  });
  if (data.stage < 3) {
    // for step 1 and 2
    // Pull the highest power plant from future in step3market
    step3Market.push(futureMarket[futureMarket.length - 1]);

    // Remove power plant from market and ppDeck
    let updatedPps = {};
    if (ppDeck.length > 0) {
      updatedPps = removePowerplant(
        futureMarket[futureMarket.length - 1],
        currentMarket,
        futureMarket,
        ppDeck,
        step3Market
      );
    } else {
      alert("Rearranging partnerships for Step 3");
      updatedPps = burRemovePowerplantStep3(
        futureMarket[futureMarket.length - 1],
        currentMarket,
        futureMarket,
        ppDeck,
        step3Market
      );
      newStage = 3;
    }

    result = {
      currentMarket: updatedPps.currentMarket,
      futureMarket: updatedPps.futureMarket,
      ppDeck: updatedPps.ppDeck,
      step3Market: step3Market,
      stage: newStage,
    };

    return result;
  } else {
    // for step 3
    let updatedPps = updatePowerplantStep3(
      currentMarket,
      futureMarket,
      ppDeck,
      step3Market
    );
    result = {
      currentMarket: updatedPps.currentMarket,
      futureMarket: updatedPps.futureMarket,
      ppDeck: updatedPps.ppDeck,
      step3Market: step3Market,
      stage: newStage,
    };
    let cityFiltered = updatePowerPlantsByHighestCity(
      result,
      highestNumberOfCities
    );
    return cityFiltered;
  }
};

export const enoughSupply = (data, type, amountNeeded, toAdd) => {
  const maxCoal = 24;
  const maxOil = 24;
  const maxGarbage = 24;
  const maxUranium = 12;
  let supplyInMarket = 0;
  if (toAdd && data.resources[type]) {
    supplyInMarket = data.resources[type].length - toAdd;
  } else if (data.resources[type]) {
    supplyInMarket = data.resources[type].length;
  }
  let supplyInPlayers = 0;
  let supplyLeft = 0;
  let playersArray = Object.keys(data.playerData);

  switch (type) {
    case "coal":
      playersArray.forEach((player) => {
        supplyInPlayers =
          supplyInPlayers + data.playerData[player].resources[type];
      });
      supplyLeft = maxCoal - supplyInMarket - supplyInPlayers;
      if (supplyLeft >= amountNeeded) {
        return amountNeeded;
      } else {
        alert(
          "Not enough space for meat! Only " +
            supplyLeft +
            " meats have been resupplied."
        );
        return supplyLeft;
      }
    case "oil":
      playersArray.forEach((player) => {
        supplyInPlayers =
          supplyInPlayers + data.playerData[player].resources[type];
      });
      supplyLeft = maxOil - supplyInMarket - supplyInPlayers;
      if (supplyLeft >= amountNeeded) {
        return amountNeeded;
      } else {
        alert(
          "Not enough space for veggies! Only " +
            supplyLeft +
            " veggies have been resupplied."
        );
        return supplyLeft;
      }
    case "garbage":
      playersArray.forEach((player) => {
        supplyInPlayers =
          supplyInPlayers + data.playerData[player].resources[type];
      });
      supplyLeft = maxGarbage - supplyInMarket - supplyInPlayers;
      if (supplyLeft >= amountNeeded) {
        return amountNeeded;
      } else {
        alert(
          "Not enough space for tea! Only " +
            supplyLeft +
            " teas have been resupplied."
        );
        return supplyLeft;
      }
    case "uranium":
      playersArray.forEach((player) => {
        supplyInPlayers =
          supplyInPlayers + data.playerData[player].resources[type];
      });
      supplyLeft = maxUranium - supplyInMarket - supplyInPlayers;
      if (supplyLeft >= amountNeeded) {
        return amountNeeded;
      } else {
        alert(
          "Not enough space for tofu! Only " +
            supplyLeft +
            " tofus have been resupplied."
        );
        return supplyLeft;
      }
  }
};

export const updateResources = (data, resourcesUsed) => {
  let dataClone = _.cloneDeep(data);
  let coalAdd;
  let oilAdd;
  let garbageAdd;
  let uraniumAdd;
  if (resourcesUsed) {
    coalAdd = resourcesUsed.coal;
    oilAdd = resourcesUsed.oil;
    garbageAdd = resourcesUsed.garbage;
    uraniumAdd = resourcesUsed.uranium;
  }
  let updateSettings =
    config.resourceUpdate[dataClone.numberOfPlayers][dataClone.stage];
  let coalToFill = enoughSupply(
    dataClone,
    "coal",
    updateSettings.coal,
    coalAdd
  );
  let oilToFill = enoughSupply(dataClone, "oil", updateSettings.oil, oilAdd);
  let garbageToFill = enoughSupply(
    dataClone,
    "garbage",
    updateSettings.garbage,
    garbageAdd
  );
  let uraniumToFill = enoughSupply(
    dataClone,
    "uranium",
    updateSettings.uranium,
    uraniumAdd
  );

  let newCoal = fillCoalOilGarbage(dataClone.resources.coal, coalToFill);
  let newOil = fillCoalOilGarbage(dataClone.resources.oil, oilToFill);
  let newGarbage = fillCoalOilGarbage(
    dataClone.resources.garbage,
    garbageToFill
  );
  let newUranium = fillUranium(dataClone.resources.uranium, uraniumToFill);
  let updatedResources = {
    coal: newCoal,
    oil: newOil,
    garbage: newGarbage,
    uranium: newUranium,
  };
  return updatedResources;
};

export const fillCoalOilGarbage = (existing, numberToAdd) => {
  let resourcesArray = _.cloneDeep(existing);

  for (var i = 0; i < numberToAdd; i++) {
    let current = resourcesArray.length;

    if (current < 3) {
      resourcesArray.push(8);
    } else if (current < 6) {
      resourcesArray.push(7);
    } else if (current < 9) {
      resourcesArray.push(6);
    } else if (current < 12) {
      resourcesArray.push(5);
    } else if (current < 15) {
      resourcesArray.push(4);
    } else if (current < 18) {
      resourcesArray.push(3);
    } else if (current < 21) {
      resourcesArray.push(2);
    } else if (current < 24) {
      resourcesArray.push(1);
    }
  }
  return resourcesArray;
};

export const fillUranium = (existing, numberToAdd) => {
  let resourcesArray = _.cloneDeep(existing);
  for (var i = 0; i < numberToAdd; i++) {
    let current = resourcesArray.length;
    if (current < 1) {
      resourcesArray.push(16);
    } else if (current < 2) {
      resourcesArray.push(14);
    } else if (current < 3) {
      resourcesArray.push(12);
    } else if (current < 4) {
      resourcesArray.push(10);
    } else if (current < 5) {
      resourcesArray.push(8);
    } else if (current < 6) {
      resourcesArray.push(7);
    } else if (current < 7) {
      resourcesArray.push(6);
    } else if (current < 8) {
      resourcesArray.push(5);
    } else if (current < 9) {
      resourcesArray.push(4);
    } else if (current < 10) {
      resourcesArray.push(3);
    } else if (current < 11) {
      resourcesArray.push(2);
    } else if (current < 12) {
      resourcesArray.push(1);
    }
  }
  return resourcesArray;
};

export const shufflePowerPlantDeckPlugCards = () => {
  let deckOrderArray = [];
  for (var i = 0; i < 13; i++) {
    deckOrderArray.push(i + 1);
  }
  shuffle(deckOrderArray);
  return deckOrderArray;
};

export const shufflePowerPlantDeckSocketCards = () => {
  let deckOrderArray = [];
  for (var i = 13; i < 42; i++) {
    deckOrderArray.push(i + 1);
  }
  shuffle(deckOrderArray);
  return deckOrderArray;
};

export const setupMarket = (numberOfPlayers) => {
  let ppDeckTemp = shufflePowerPlantDeckPlugCards();
  let draw = [];
  let currentMarket = [];
  let futureMarket = [];

  // take 8 cards
  for (var i = 0; i < 8; i++) {
    draw.push(ppDeckTemp[0]);
    ppDeckTemp.shift();
  }

  // sort them
  draw.sort((a, b) => a - b);

  // put them in order
  for (var i = 0; i < 4; i++) {
    currentMarket.push(draw[i]);
  }
  for (var i = 4; i < 8; i++) {
    futureMarket.push(draw[i]);
  }

  // remove the wild
  let wild = ppDeckTemp[0];
  ppDeckTemp.shift();

  // shuffle the socket deck
  let socketDeck = shufflePowerPlantDeckSocketCards();

  // remove from plug and socket cards
  switch (numberOfPlayers) {
    case 2:
      ppDeckTemp.shift();
      socketDeck.shift();
      socketDeck.shift();
      socketDeck.shift();
      socketDeck.shift();
      socketDeck.shift();
      break;
    case 3:
      ppDeckTemp.shift();
      ppDeckTemp.shift();
      socketDeck.shift();
      socketDeck.shift();
      socketDeck.shift();
      socketDeck.shift();
      socketDeck.shift();
      socketDeck.shift();
      break;
    case 4:
      ppDeckTemp.shift();
      socketDeck.shift();
      socketDeck.shift();
      socketDeck.shift();
      break;
  }

  // mix the two decks together
  for (var i = 0; i < ppDeckTemp.length; i++) {
    socketDeck.push(ppDeckTemp[i]);
  }
  shuffle(socketDeck);

  // put the wild on the top
  socketDeck.unshift(wild);

  return {
    currentMarket: currentMarket,
    futureMarket: futureMarket,
    ppDeck: socketDeck,
  };
};

export const removePowerplantStep3 = (
  selectedPp,
  currentMarket,
  futureMarket,
  ppDeck,
  step3Market
) => {
  let currentMarketTemp = _.cloneDeep(currentMarket);
  let futureMarketTemp = _.cloneDeep(futureMarket);
  let ppDeckTemp = _.cloneDeep(ppDeck);
  let step3MarketTemp = _.cloneDeep(step3Market);

  // remove the selected item from current
  if (currentMarketTemp.includes(selectedPp)) {
    let index = currentMarketTemp.indexOf(selectedPp);
    currentMarketTemp.splice(index, 1);
  }

  // replace from the step3Market deck if there are any
  if (step3MarketTemp.length > 0) {
    currentMarketTemp.push(step3MarketTemp[0]);
    step3MarketTemp.shift();
    currentMarketTemp.sort((a, b) => a - b);
  }
  let result = {
    currentMarket: currentMarketTemp,
    futureMarket: futureMarketTemp,
    ppDeck: ppDeckTemp,
    step3Market: step3MarketTemp,
  };
  return result;
};

export const updatePowerplantStep3 = (
  currentMarket,
  futureMarket,
  ppDeck,
  step3Market
) => {
  let currentMarketTemp = _.cloneDeep(currentMarket);
  let futureMarketTemp = _.cloneDeep(futureMarket);
  let ppDeckTemp = _.cloneDeep(ppDeck);
  let step3MarketTemp = _.cloneDeep(step3Market);

  // remove the lowest from the current market
  if (currentMarketTemp.length > 0) {
    currentMarketTemp.pop();
  }
  // replace from the step3Market deck if there are any
  if (step3MarketTemp.length > 0) {
    currentMarketTemp.push(step3MarketTemp[0]);
    step3MarketTemp.shift();
    currentMarketTemp.sort((a, b) => a - b);
  }
  let result = {
    currentMarket: currentMarketTemp,
    futureMarket: futureMarketTemp,
    ppDeck: ppDeckTemp,
    step3Market: step3MarketTemp,
  };
  return result;
};

export const removePowerplant = (
  selectedPp,
  currentMarket,
  futureMarket,
  ppDeck,
  step3Market
) => {
  let currentMarketTemp = _.cloneDeep(currentMarket);
  let futureMarketTemp = _.cloneDeep(futureMarket);
  let ppDeckTemp = _.cloneDeep(ppDeck);
  let step3MarketTemp = _.cloneDeep(step3Market);

  if (currentMarketTemp.includes(selectedPp)) {
    let index = currentMarketTemp.indexOf(selectedPp);
    currentMarketTemp.splice(index, 1);
  } else if (futureMarketTemp.includes(selectedPp)) {
    let index = futureMarketTemp.indexOf(selectedPp);
    futureMarketTemp.splice(index, 1);
  } else if (ppDeckTemp.includes(selectedPp)) {
    let index = ppDeckTemp.indexOf(selectedPp);
    ppDeckTemp.splice(index, 1);
  }

  // pool the new card and existing cards and re-order them
  let newPool = [];

  // see if it's a transition to stage 3
  if (ppDeckTemp.length > 0) {
    newPool.push(ppDeckTemp[0]);
  } else if (
    // only deal a new card if there are 7 cards or fewer. The lowest card will be dropped during the transition.
    step3MarketTemp.length > 0 &&
    currentMarketTemp.length + futureMarketTemp.length < 7
  ) {
    shuffle(step3MarketTemp);
    newPool.push(step3MarketTemp[0]);
    step3MarketTemp.shift();
  } else {
    alert("The Step 3 card has been found");
  }

  for (var i = 0; i < currentMarketTemp.length; i++) {
    newPool.push(currentMarketTemp[i]);
  }
  for (var i = 0; i < futureMarketTemp.length; i++) {
    newPool.push(futureMarketTemp[i]);
  }
  // sort them
  newPool.sort((a, b) => a - b);

  let result = {};
  result.currentMarket = [];
  result.futureMarket = [];
  result.step3Market = step3MarketTemp;

  // separate them to current and future market
  for (var i = 0; i < 4; i++) {
    result.currentMarket.push(newPool[i]);
  }
  for (var i = 4; i < 8; i++) {
    if (newPool[i]) {
      result.futureMarket.push(newPool[i]);
    }
  }

  // remove the new card from the deck temp
  ppDeckTemp.shift();

  result.ppDeck = ppDeckTemp;

  return result;
};

export const burRemovePowerplantStep3 = (
  selectedPp,
  currentMarket,
  futureMarket,
  ppDeck,
  step3Market
) => {
  let currentMarketTemp = _.cloneDeep(currentMarket);
  let futureMarketTemp = _.cloneDeep(futureMarket);
  let ppDeckTemp = _.cloneDeep(ppDeck);
  let step3MarketTemp = _.cloneDeep(step3Market);

  if (currentMarketTemp.includes(selectedPp)) {
    let index = currentMarketTemp.indexOf(selectedPp);
    currentMarketTemp.splice(index, 1);
  } else if (futureMarketTemp.includes(selectedPp)) {
    let index = futureMarketTemp.indexOf(selectedPp);
    futureMarketTemp.splice(index, 1);
  } else if (ppDeckTemp.includes(selectedPp)) {
    let index = ppDeckTemp.indexOf(selectedPp);
    ppDeckTemp.splice(index, 1);
  }

  // pool the new card and existing cards and re-order them
  let newPool = [];

  // pool all of the existing cards into one place
  for (var i = 0; i < currentMarketTemp.length; i++) {
    newPool.push(currentMarketTemp[i]);
  }
  for (var i = 0; i < futureMarketTemp.length; i++) {
    newPool.push(futureMarketTemp[i]);
  }
  // sort them
  newPool.sort((a, b) => a - b);

  // remove the smallest card
  newPool.shift();

  let result = {};
  result.currentMarket = [];
  result.futureMarket = [];
  result.step3Market = step3MarketTemp;

  // separate them to current and future market
  for (var i = 0; i < 6; i++) {
    if (newPool[i]) {
      result.currentMarket.push(newPool[i]);
    }
  }
  result.ppDeck = ppDeckTemp;
  return result;
};

export const lastPlayerToBuyPlant = (data, user) => {
  const playerData = data.playerData;
  const round = data.round;
  const players = Object.keys(playerData);
  let result = true;
  for (var i = 0; i < players.length; i++) {
    if (playerData[players[i]].name !== user) {
      if (!playerData[players[i]].rounds[round].ppComplete) {
        result = false;
      }
    }
  }
  return result;
};

export const lastPlayerToBuyResources = (data, user) => {
  const playerData = data.playerData;
  const round = data.round;
  const players = Object.keys(playerData);
  let result = true;
  for (var i = 0; i < players.length; i++) {
    if (playerData[players[i]].name !== user) {
      if (!playerData[players[i]].rounds[round].resourcesComplete) {
        result = false;
      }
    }
  }
  return result;
};

export const lastPlayerToBuyCities = (data, user) => {
  const playerData = data.playerData;
  const round = data.round;
  const players = Object.keys(playerData);
  let result = true;
  for (var i = 0; i < players.length; i++) {
    if (playerData[players[i]].name !== user) {
      if (!playerData[players[i]].rounds[round].citiesComplete) {
        result = false;
      }
    }
  }
  return result;
};

export const lastPlayerToBureaucracy = (data, user) => {
  const playerData = data.playerData;
  const round = data.round;
  const players = Object.keys(playerData);
  let result = true;
  for (var i = 0; i < players.length; i++) {
    if (playerData[players[i]].name !== user) {
      if (!playerData[players[i]].rounds[round].bureaucracyComplete) {
        result = false;
      }
    }
  }
  return result;
};

export const allPlayersBureaucracied = (data) => {
  const playerData = data.playerData;
  const round = data.round;
  const players = Object.keys(playerData);
  let result = true;
  for (var i = 0; i < players.length; i++) {
    if (!playerData[players[i]].rounds[round].bureaucracyComplete) {
      result = false;
    }
  }
  return result;
};

export const numberOfResourceSpaces = (data, resource) => {
  let totalCoalSlots = 0;
  let totalOilSlots = 0;
  let totalGarbageSlots = 0;
  let totalUraniumSlots = 0;
  let totalHybridSlots = 0;
  data.pps.forEach((powerplantId) => {
    let plant = config.powerPlants[powerplantId];
    if (plant.type == "coal") {
      totalCoalSlots += plant.resourcesNeeded * 2;
    } else if (plant.type == "oil") {
      totalOilSlots += plant.resourcesNeeded * 2;
    } else if (plant.type == "garbage") {
      totalGarbageSlots += plant.resourcesNeeded * 2;
    } else if (plant.type == "uranium") {
      totalUraniumSlots += plant.resourcesNeeded * 2;
    } else if (plant.type == "hybrid") {
      totalHybridSlots += plant.resourcesNeeded * 2;
    }
  });

  switch (resource) {
    case "coal":
      return totalCoalSlots;
      break;
    case "oil":
      return totalOilSlots;
      break;
    case "garbage":
      return totalGarbageSlots;
      break;
    case "uranium":
      return totalUraniumSlots;
      break;
    case "hybrid":
      return totalHybridSlots;
      break;
  }
};

export const numberOfAvailableHybridSpaces = (data) => {
  let totalHybridSpaces = numberOfResourceSpaces(data, "hybrid");
  // see if hybrid spaces are already occupied
  // is oil using up any of the space?
  let oilNumber = data.resources.oil;
  let oilSpaces = numberOfResourceSpaces(data, "oil");
  let hybridSpacesUsedByOil = 0;
  if (oilNumber > oilSpaces) {
    hybridSpacesUsedByOil = oilNumber - oilSpaces;
  }
  let coalNumber = data.resources.coal;
  let coalSpaces = numberOfResourceSpaces(data, "coal");
  let hybridSpacesUsedByCoal = 0;
  if (coalNumber > coalSpaces) {
    hybridSpacesUsedByCoal = coalNumber - coalSpaces;
  }
  let totalUsedHybridSpaces = hybridSpacesUsedByCoal + hybridSpacesUsedByOil;
  return totalHybridSpaces - totalUsedHybridSpaces;
};

export const numberOfAvailableResourceSpaces = (data, resource) => {
  let totalSpaces = numberOfResourceSpaces(data, resource);
  if (resource == "coal" || resource == "oil") {
    let numberOfAvailableHybridSpacesNumber =
      numberOfAvailableHybridSpaces(data);
    // if full check the remaining space
    if (totalSpaces > data.resources[resource]) {
      let remainingSpaces = totalSpaces - data.resources[resource];
      return remainingSpaces;
    } else {
      let remainingSpaces = numberOfAvailableHybridSpacesNumber;
      return remainingSpaces;
    }
  } else {
    let remainingSpaces = totalSpaces - data.resources[resource];
    return remainingSpaces;
  }
};

export const buyResource = (data, user, currentUserData, dbGame, resource) => {
  // figure out the cost of coal
  let cost = data.resources[resource][data.resources[resource].length - 1];

  // if user can afford it, add to their inventory
  if (currentUserData.cash >= cost) {
    // if user has the spaces for it
    if (numberOfAvailableResourceSpaces(currentUserData, resource) > 0) {
      let pathResource = "playerData." + user + ".resources." + resource;
      let pathCash = "playerData." + user + ".cash";
      let obj = {};
      obj[pathResource] = currentUserData.resources[resource] + 1;
      obj[pathCash] = currentUserData.cash - cost;
      dbGame.update(obj);
      // remove from market
      let modifiedCoal = _.cloneDeep(data.resources[resource]);
      modifiedCoal.pop();
      let masterPathResource = "resources." + resource;
      let masterObj = {};
      masterObj[masterPathResource] = modifiedCoal;
      dbGame.update(masterObj);
    } else {
      alert("Your partnerships can't hold more of this ingredient.");
    }
  } else {
    alert("Sorry. You can't afford more.");
  }
};

export const isGameOver = (data) => {
  let nop = data.numberOfPlayers;
  let endGameCities;
  if (nop == 2) {
    endGameCities = 18;
  } else if (nop == 3) {
    endGameCities = 17;
  } else if (nop == 4) {
    endGameCities = 17;
  } else if (nop == 5) {
    endGameCities = 15;
  } else if (nop == 6) {
    endGameCities = 14;
  }
  let result = false;
  let playerData = data.playerData;
  let playerKeys = Object.keys(playerData);
  playerKeys.forEach((player) => {
    if (playerData[player].cities.length >= endGameCities) {
      result = true;
    }
  });
  return result;
};

export const getWinners = (data) => {
  let playerData = data.playerData;
  let playerSnapshot = [];
  let playerKeys = Object.keys(playerData);
  playerKeys.forEach((player) => {
    let obj = {};
    obj.citiesPowered = playerData[player].rounds[data.round - 1].citiesPowered;
    obj.cash = playerData[player].cash;
    obj.name = player;
    playerSnapshot.push(obj);
  });
  playerSnapshot.sort(function (a, b) {
    if (a.citiesPowered == b.citiesPowered) {
      // Price is only important when cities are the same
      return b.cash - a.cash;
    }
    return b.citiesPowered > a.citiesPowered ? 1 : -1;
  });
  let resultsArray = [];
  playerSnapshot.forEach((player) => {
    resultsArray.push(player.name);
  });
  console.log(resultsArray);
  return resultsArray;
};

export const updatePlayerOrder = (data) => {
  let playerData = data.playerData;
  let playerSnapshot = [];
  let playerKeys = Object.keys(playerData);
  playerKeys.forEach((player) => {
    let obj = {};
    obj.cities = playerData[player].cities.length;
    // sort power plants by cost
    let ppTemp = _.cloneDeep(playerData[player].pps);
    ppTemp.sort((a, b) => a - b);
    ppTemp.reverse();
    obj.power = ppTemp[0];
    obj.name = player;
    playerSnapshot.push(obj);
  });
  playerSnapshot.sort(function (a, b) {
    if (a.cities == b.cities) {
      // Price is only important when cities are the same
      return a.power - b.power;
    }
    return a.cities > b.cities ? 1 : -1;
  });
  let resultsArray = [];
  playerSnapshot.forEach((player) => {
    resultsArray.push(player.name);
  });
  return resultsArray;
};

export const whoseTurnBureaucracy = (data) => {
  let playersLeft = [];
  for (var i = 0; i < data.playerOrder.length; i++) {
    if (data.playerData[data.playerOrder[i]].rounds[data.round]) {
      if (
        !data.playerData[data.playerOrder[i]].rounds[data.round]
          .bureaucracyComplete
      ) {
        console.log("adding: " + data.playerOrder[i]);
        playersLeft.push(data.playerOrder[i]);
      }
    }
  }
  console.log(playersLeft);
  return playersLeft.join(", ");
};

export const whoseTurn = (data, step) => {
  let activePlayer = "";
  for (var i = 0; i < data.playerOrder.length; i++) {
    if (data.playerData[data.playerOrder[i]].rounds[data.round]) {
      if (!data.playerData[data.playerOrder[i]].rounds[data.round][step]) {
        activePlayer = data.playerOrder[i];
        break;
      }
    }
  }
  return activePlayer;
};

export const whoseTurnBidP = (data, step) => {
  const reversePlayerOrder = [...data.playerOrder].reverse();
  let activePlayer = "";
  for (var i = 0; i < reversePlayerOrder.length; i++) {
    if (data.playerData[reversePlayerOrder[i]].rounds[data.round]) {
      if (!data.playerData[reversePlayerOrder[i]].rounds[data.round][step]) {
        activePlayer = reversePlayerOrder[i];
        break;
      }
    }
  }
  return activePlayer;
};

export const generateMap = (that) => {
  // login prep
  // Prep map data
  let map = config.map;
  let citiesArray = [];
  let connectionsArray = [];

  for (let key in map.cities) {
    // add all cities to an array
    citiesArray.push(map.cities[key]);

    // add all connections to an array
    for (let connection in map.cities[key].connections) {
      connectionsArray.push({
        startCity: key,
        endCity: map.cities[key].connections[connection].id,
        cost: map.cities[key].connections[connection].cost,
      });
    }
  }

  // Disable logic for cities that can't be bought (cities that aren't connected to existing cities)

  var ownedCities = {};
  let connectedCities = [];
  /*
  let selectedCities = that.state.selectedCities;

  // if user owns cities, add those as connections
  if (
    Object.keys(that.state.gameState.ownedCities).length > 0 &&
    that.state.userProfile.uuid
  ) {
    ownedCities = that.state.gameState.ownedCities[that.state.userProfile.uuid];
    for (let i = 0; i < ownedCities.length; i++) {
      for (let t in map.cities[ownedCities[i]].connections) {
        connectedCities.push(map.cities[ownedCities[i]].connections[t].id);
      }
    }
  }

  // if user has selected cities, add those as connections
  if (selectedCities.length > 0) {
    for (let i = 0; i < selectedCities.length; i++) {
      for (let t in map.cities[selectedCities[i]].connections) {
        connectedCities.push(map.cities[selectedCities[i]].connections[t].id);
      }
    }
  }

  // remove duplicates from connections
  connectedCities = [...new Set(connectedCities)];
  // remove owned cities from connections
  for (let i = 0; i < ownedCities.length; i++) {
    if (connectedCities.includes(ownedCities[i])) {
      let targetIndex = connectedCities.indexOf(ownedCities[i]);
      connectedCities.splice(targetIndex, 1);
    }
  }

  // if existing selected city is no longer connected, remove it
  if (selectedCities.length > 0) {
    for (let i = 0; i < selectedCities.length; i++) {
      if (!connectedCities.includes(selectedCities[i])) {
        let targetIndex = selectedCities.indexOf(selectedCities[i]);
        selectedCities.splice(targetIndex, 1);
        if (that.state.selectedCities !== selectedCities) {
          that.setState({
            selectedCities: selectedCities,
          });
        }
      }
    }
  }*/

  let uniqueConnectionsArray = [];

  // remove repeated connections
  for (let i in connectionsArray) {
    // create an array of duplicate connections
    let duplicatesArray = uniqueConnectionsArray.filter(
      // eslint-disable-next-line no-loop-func
      (connection) =>
        (connection.startCity == connectionsArray[i].startCity &&
          connection.endCity == connectionsArray[i].endCity) ||
        (connection.startCity == connectionsArray[i].endCity &&
          connection.endCity == connectionsArray[i].startCity)
    );
    // if no duplicates, then add new unique connection
    if (duplicatesArray.length == 0) {
      uniqueConnectionsArray.push(connectionsArray[i]);
    }
  }

  return {
    citiesArray: citiesArray,
    ownedCities: ownedCities,
    connectedCities: connectedCities,
    //selectedCities: selectedCities,
    connectionsArray: connectionsArray,
    uniqueConnectionsArray: uniqueConnectionsArray,
  };
};

export const pyth = (x, y) => {
  if (typeof x !== "number" || typeof y !== "number") return false;
  return Math.sqrt(x * x + y * y);
};

export const radians_to_degrees = (radians) => {
  var pi = Math.PI;
  return radians * (180 / pi);
};

export const playersInCity = (playerData, city) => {
  const playerKeys = Object.keys(playerData);
  let numberOfPlayersInCity = 0;
  playerKeys.forEach((player, index) => {
    if (playerData[player].cities.includes(city)) {
      numberOfPlayersInCity++;
    }
  });
  return numberOfPlayersInCity;
};

export const isCityFull = (playerData, city, stage) => {
  let numberOfOccupants = playersInCity(playerData, city);
  if (stage == 1) {
    if (numberOfOccupants > 0) {
      return true;
    } else {
      return false;
    }
  } else if (stage == 2) {
    if (numberOfOccupants > 1) {
      return true;
    } else {
      return false;
    }
  } else if (stage == 3) {
    if (numberOfOccupants > 2) {
      return true;
    } else {
      return false;
    }
  }
};

export const isCityConnected = (userCities, selectedCity) => {
  // find cities connected to selected city
  if (selectedCity) {
    let connectedCities = Object.keys(
      config.map.cities[selectedCity].connections
    );
    const found = connectedCities.some((r) => userCities.indexOf(r) >= 0);
    return found;
  } else {
    return false;
  }
};

export const lowestCostOfBuying = (playerData, userCities, city) => {
  let cost = 0;
  // get base cost of city
  let occupants = playersInCity(playerData, city);
  if (occupants == 0) {
    cost += 10;
  } else if (occupants == 1) {
    cost += 15;
  } else if (occupants == 2) {
    cost += 20;
  }

  // see what connections exist
  let connectedCities = [];
  if (config.map.cities[city]) {
    connectedCities = Object.keys(config.map.cities[city].connections);
  }
  let overlappingCities = [];
  connectedCities.forEach((connectedCity) => {
    if (userCities.includes(connectedCity)) {
      overlappingCities.push(connectedCity);
    }
  });
  let connectionCosts = [];
  overlappingCities.forEach((overlappingCity) => {
    connectionCosts.push(
      config.map.cities[city].connections[overlappingCity].cost
    );
  });
  connectionCosts.sort((a, b) => a - b);
  if (connectionCosts[0]) {
    cost += connectionCosts[0];
  }
  return cost;
};

export const removeA = (array, term) => {
  let ax = array.indexOf(term);
  array.splice(ax, 1);
  return array;
};

export const calcProfit = (citiesPowered) => {
  switch (citiesPowered) {
    case 0:
      return 10;
    case 1:
      return 22;
    case 2:
      return 33;
    case 3:
      return 44;
    case 4:
      return 54;
    case 5:
      return 64;
    case 6:
      return 73;
    case 7:
      return 82;
    case 8:
      return 90;
    case 9:
      return 98;
    case 10:
      return 105;
    case 11:
      return 112;
    case 12:
      return 118;
    case 13:
      return 124;
    case 14:
      return 129;
    case 15:
      return 134;
    case 16:
      return 138;
    case 17:
      return 142;
    case 18:
      return 145;
    case 19:
      return 148;
    case 20:
      return 150;
  }
};
