import React, { useState, useEffect, useRef } from "react";
import firebase from "../Firebase/Firestore";
import * as tools from "../functions";
import * as config from "../config";
import Map from "../Pages/Map";
import _ from "lodash";
import useSound from "use-sound";
import soundYourTurn from "../assets/images/icq-uh-oh.mp3";
import soundKaChing from "../assets/images/kaching.mp3";

import { Row, Col, Tabs, Tab } from "react-bootstrap";
import StepTransition from "./StepTransition";
import PowerPlants from "./PowerPlants";
import Resources from "./Resources";
import Bureaucracy from "./Bureaucracy";
import iconCash from "../assets/images/money-icon.png";
import iconMeat from "../assets/images/icon-meat.png";
import iconChoi from "../assets/images/icon-choi.png";
import iconMixed from "../assets/images/icon-mixed.png";
import iconTea from "../assets/images/icon-tea.png";
import iconTofu from "../assets/images/icon-tofu.png";
import iconStage from "../assets/images/icon-stage.png";
import iconRound from "../assets/images/icon-turn.png";
import iconCards from "../assets/images/icon-cards.png";
import iconLocation from "../assets/images/gate.png";
import ReactTooltip from "react-tooltip";
import PlayerIcon from "./PlayerIcon";
import Winner from "./Winner";

const ActiveGame = (props) => {
  const data = props.data;

  const [activeTab, setActiveTab] = useState("pp");
  const [network, setNetwork] = useState({});
  const [profileDetails, setProfileDetails] = useState(false);
  const [showTransition, setShowTransition] = useState(true);
  const [personToGo, setPersonToGo] = useState("");
  const [playYourTurn] = useSound(soundYourTurn, { volume: 0.25 });
  const [playKaChing] = useSound(soundKaChing, { volume: 0.25 });

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevData = usePrevious(data);
  useEffect(() => {
    if (prevData) {
      if (prevData?.step !== data.step) {
        // process here
        setShowTransition(true);
      }
    }
  }, [data]);

  const updateGameState = (command, value) => {
    switch (command) {
      case "selectedCity":
        break;
      default:
        break;
    }
  };

  const updateNetwork = (value) => {
    if (value?.body?.nodes && network?.body?.nodes) {
      if (_.isEqual(value.body.nodes, network.body.nodes)) {
      }
    } else {
      setNetwork(value);
    }
  };

  // Notifications that it's your turn
  const prevPersonToGo = usePrevious(personToGo);
  useEffect(() => {
    let activePlayerToGo;
    if (data.step == "bidP") {
      activePlayerToGo = tools.whoseTurnBidP(data, "ppComplete");
    } else if (data.step == "resources") {
      activePlayerToGo = tools.whoseTurn(data, "resourcesComplete");
    } else if (data.step == "map") {
      activePlayerToGo = tools.whoseTurn(data, "citiesComplete");
    } else if (data.step == "bureaucracy") {
      activePlayerToGo = tools.whoseTurnBureaucracy(data);
    }
    if (data.step !== "bureaucracy") {
      if (
        prevPersonToGo !== props.user &&
        activePlayerToGo.includes(props.user)
      ) {
        playYourTurn();
      }
    } else {
      if (prevPersonToGo !== activePlayerToGo) {
        playKaChing();
      }
    }

    setPersonToGo(activePlayerToGo);
  }, [data]);

  let ppTitle = "Bid for Contracts";
  let resourcesTitle = "Buy Ingredients";
  let mapTitle = "Buy Locations";
  let bureaucracyTitle = "吃飯";
  let ppClass = "";
  let resourcesClass = "";
  let mapClass = "";
  let bureaucracyClass = "";
  let playersClass = "";

  switch (data.step) {
    case "bidP":
      ppClass = "attn";
      break;
    case "resources":
      resourcesClass = "attn";
      break;
    case "map":
      mapClass = "attn";
      break;
    case "bureaucracy":
      bureaucracyClass = "attn";
      break;
  }

  let bidOrder = _.cloneDeep(data.playerOrder);
  bidOrder.reverse();

  tools.updatePlayerOrder(data);
  let zIndex = "belowMenu";

  if (profileDetails) {
    zIndex = "aboveMenu";
  }

  return (
    <div className="gameWindow">
      {data.gameOver && <Winner data={data} />}
      {showTransition && (
        <StepTransition
          data={data}
          setActiveTab={setActiveTab}
          showTransition={setShowTransition}
        />
      )}
      {profileDetails && <div className="overlay"></div>}
      <ReactTooltip id="encourage" type="success" delayShow="1000" />
      <ReactTooltip id="inactive" type="info" delayShow="1000" />
      <div className={"playerList " + zIndex}>
        <div className="playerOrderIcons">
          {data.playerOrder.map((player, index) => {
            let iconClass = "character";
            let playerName = "";
            if (player == props.user) {
              iconClass = "character self";
              playerName = <div className="playerName">{props.user}</div>;
            } else {
              playerName = <div className="playerName">{player}</div>;
            }
            return (
              <PlayerIcon
                key={index}
                player={data.playerData[player]}
                iconClass={iconClass}
                zIndex={setProfileDetails}
              />
            );
          })}
        </div>
        <div className="gameMarker">
          <div className="marker">
            <div className="title">Step 2 at</div>
            <div className="stepNumber">
              <img src={iconLocation} /> {data.numberOfPlayers < 6 && "7"}
              {data.numberOfPlayers == 6 && "6"}
            </div>
          </div>
          <div className="marker">
            <div className="title">Step 3 in</div>
            <div className="stepNumber">
              {data.ppDeck.length > 0 ? (
                <>
                  <img src={iconCards} /> {data.ppDeck.length + 1}
                </>
              ) : (
                "Now"
              )}
            </div>
          </div>
          <div className="marker">
            <div className="title">End game at</div>
            <div className="stepNumber">
              <img src={iconLocation} /> {data.numberOfPlayers == 2 && "18"}
              {(data.numberOfPlayers == 3 || data.numberOfPlayers == 4) && "17"}
              {data.numberOfPlayers == 5 && "15"}
              {data.numberOfPlayers == 6 && "14"}
            </div>
          </div>
        </div>
      </div>
      <div className="playerBar">
        <div className="gameInfo">
          <div className="stage">
            <img src={iconStage} /> {data.stage}
          </div>
          <div className="round">
            <img src={iconRound} /> {data.round}
            {"  -  "}
            {data.step == "bidP" && "Bid for Contracts"}
            {data.step == "resources" && "Buy Ingredients"}
            {data.step == "map" && "Buy Locations"}
            {data.step == "bureaucracy" && "吃飯"}
          </div>
          <div className="activePlayer">Waiting on: {personToGo}</div>
        </div>
        <div className="playerInfo">
          <div className="inventory">
            <div className="location">
              <img src={iconLocation} />{" "}
              {data.playerData[props.user].cities.length}
            </div>
            <div className="cash">
              <img src={iconCash} /> NT${data.playerData[props.user].cash}
            </div>
            <div className="breakBorder"></div>
            <div className="coal">
              <img src={iconMeat} />{" "}
              {data.playerData[props.user].resources.coal} /{" "}
              {tools.numberOfResourceSpaces(
                data.playerData[props.user],
                "coal"
              )}
            </div>
            <div className="oil">
              <img src={iconChoi} /> {data.playerData[props.user].resources.oil}{" "}
              /{" "}
              {tools.numberOfResourceSpaces(data.playerData[props.user], "oil")}
            </div>
            <div className="hybrid">
              {"("}
              <img src={iconMixed} />{" "}
              {tools.numberOfResourceSpaces(
                data.playerData[props.user],
                "hybrid"
              ) -
                tools.numberOfAvailableHybridSpaces(
                  data.playerData[props.user]
                )}{" "}
              /{" "}
              {tools.numberOfResourceSpaces(
                data.playerData[props.user],
                "hybrid"
              )}
              {")"}
            </div>
            <div className="garbage">
              <img src={iconTea} />{" "}
              {data.playerData[props.user].resources.garbage} /{" "}
              {tools.numberOfResourceSpaces(
                data.playerData[props.user],
                "garbage"
              )}
            </div>
            <div className="uranium">
              <img src={iconTofu} />{" "}
              {data.playerData[props.user].resources.uranium} /{" "}
              {tools.numberOfResourceSpaces(
                data.playerData[props.user],
                "uranium"
              )}
            </div>
          </div>
        </div>
      </div>
      <Row className="mainArea">
        <Col>
          <Tabs
            id="controlled-tab-example"
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
          >
            <Tab eventKey="pp" title={ppTitle} tabClassName={ppClass}>
              <PowerPlants
                db={props.db}
                data={data}
                user={props.user}
                setActiveTab={setActiveTab}
                showTransition={setShowTransition}
              />
            </Tab>
            <Tab
              eventKey="resources"
              title={resourcesTitle}
              tabClassName={resourcesClass}
              showTransition={setShowTransition}
            >
              <Resources db={props.db} data={data} user={props.user} />
            </Tab>
            <Tab eventKey="map" title={mapTitle} tabClassName={mapClass}>
              <Map
                updateGameState={updateGameState}
                setNetwork={updateNetwork}
                user={props.user}
                db={props.db}
                data={data}
                showTransition={setShowTransition}
              />
            </Tab>
            <Tab
              eventKey="bureaucracy"
              title={bureaucracyTitle}
              tabClassName={bureaucracyClass}
              showTransition={setShowTransition}
            >
              <Bureaucracy db={props.db} data={data} user={props.user} />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default ActiveGame;
