import React, { useState, useEffect } from "react";
import * as tools from "../functions";
import * as config from "../config";
import _ from "lodash";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import Plant from "./Plant";
import iconCash from "../assets/images/money-icon.png";
import iconMeat from "../assets/images/icon-meat.png";
import iconChoi from "../assets/images/icon-choi.png";
import iconMixed from "../assets/images/icon-mixed.png";
import iconTea from "../assets/images/icon-tea.png";
import iconTofu from "../assets/images/icon-tofu.png";
import iconStage from "../assets/images/icon-stage.png";
import iconRound from "../assets/images/icon-turn.png";
import iconLocation from "../assets/images/gate.png";

const Bureaucracy = (props) => {
  const data = props.data;
  const user = props.user;
  const dbGame = props.db.collection("games").doc(data.id);
  const currentUserData = data.playerData[user];
  const [livePp, setLivePp] = useState([]);
  const [power, setPower] = useState(0);
  const [citiesPowered, setCitiesPowered] = useState(0);
  const [profit, setProfit] = useState(10);
  const [coal, setCoal] = useState(0);
  const [oil, setOil] = useState(0);
  const [garbage, setGarbage] = useState(0);
  const [uranium, setUranium] = useState(0);

  const handleToggle = (pp, action, hybrid) => {
    // check if you have enough resources
    let plantInfo = config.powerPlants[pp];

    if (action == "add") {
      if (!hybrid) {
        setLivePp((oldArray) => [
          ...oldArray,
          { id: pp, type: plantInfo.type },
        ]);
      } else {
        setLivePp((oldArray) => [...oldArray, { id: pp, type: hybrid }]);
      }
      setPower(power + plantInfo.citiesPowered);
      if (plantInfo.type == "coal") {
        setCoal(coal + plantInfo.resourcesNeeded);
      } else if (plantInfo.type == "oil") {
        setOil(oil + plantInfo.resourcesNeeded);
      } else if (plantInfo.type == "garbage") {
        setGarbage(garbage + plantInfo.resourcesNeeded);
      } else if (plantInfo.type == "uranium") {
        setUranium(uranium + plantInfo.resourcesNeeded);
      } else if (plantInfo.type == "hybrid") {
        if (hybrid == "coal") {
          setCoal(coal + plantInfo.resourcesNeeded);
        } else if (hybrid == "oil") {
          setOil(oil + plantInfo.resourcesNeeded);
        } else if (hybrid == "5050") {
          setCoal(coal + plantInfo.resourcesNeeded / 2);
          setOil(oil + plantInfo.resourcesNeeded / 2);
        } else if (hybrid == "majCoal") {
          setCoal(coal + 2);
          setOil(oil + 1);
        } else if (hybrid == "majOil") {
          setCoal(coal + 1);
          setOil(oil + 2);
        }
      }
    } else if (action == "remove") {
      // find the object
      const index = livePp.findIndex((plant) => plant.id == pp);
      let resourceType = livePp[index].type;
      if (resourceType == "coal") {
        setCoal(coal - plantInfo.resourcesNeeded);
      } else if (resourceType == "oil") {
        setOil(oil - plantInfo.resourcesNeeded);
      } else if (resourceType == "garbage") {
        setGarbage(garbage - plantInfo.resourcesNeeded);
      } else if (resourceType == "uranium") {
        setUranium(uranium - plantInfo.resourcesNeeded);
      } else if (resourceType == "5050") {
        setCoal(coal - plantInfo.resourcesNeeded / 2);
        setOil(oil - plantInfo.resourcesNeeded / 2);
      } else if (resourceType == "majCoal") {
        setCoal(coal - 2);
        setOil(oil - 1);
      } else if (resourceType == "majOil") {
        setCoal(coal - 1);
        setOil(oil - 2);
      }
      setPower(power - plantInfo.citiesPowered);
      let updatedLivePp = _.cloneDeep(livePp);
      updatedLivePp = updatedLivePp.filter(function (obj) {
        return obj.id !== pp;
      });
      setLivePp(updatedLivePp);
    }
  };

  useEffect(() => {
    if (power < currentUserData.cities.length) {
      setCitiesPowered(power);
    } else {
      setCitiesPowered(currentUserData.cities.length);
    }
  }, [power]);

  useEffect(() => {
    setProfit(tools.calcProfit(citiesPowered));
  }, [citiesPowered]);

  const handleDelete = (pp) => {
    let pps = _.cloneDeep(currentUserData.pps);
    let removeIndex = pps.indexOf(pp);
    pps.splice(removeIndex, 1);
    let pathPps = "playerData." + user + ".pps";
    let obj = {};
    obj[pathPps] = pps;
    dbGame.update(obj);
  };

  const adjustResource = (resource, action) => {
    let pathPps = "playerData." + user + ".resources." + resource;
    let obj = {};
    if (action == "add") {
      obj[pathPps] = currentUserData.resources[resource] + 1;
    } else if (action == "subtract") {
      obj[pathPps] = currentUserData.resources[resource] - 1;
    }
    dbGame.update(obj);
  };

  const adjustMoney = (action) => {
    let pathPps = "playerData." + user + ".cash";
    let obj = {};
    if (action == "add") {
      obj[pathPps] = currentUserData.cash + 1;
    } else if (action == "subtract") {
      obj[pathPps] = currentUserData.cash - 1;
    }
    dbGame.update(obj);
  };

  const handleBureaucracy = () => {
    // check if user has resources
    const r = currentUserData.resources;
    if (
      r.coal >= coal &&
      r.oil >= oil &&
      r.garbage >= garbage &&
      r.uranium >= uranium
    ) {
      let pathCash = "playerData." + user + ".cash";
      let pathCoal = "playerData." + user + ".resources.coal";
      let pathOil = "playerData." + user + ".resources.oil";
      let pathGarbage = "playerData." + user + ".resources.garbage";
      let pathUranium = "playerData." + user + ".resources.uranium";
      let pathRound =
        "playerData." + user + ".rounds." + data.round + ".bureaucracyComplete";
      let pathRoundPowered =
        "playerData." + user + ".rounds." + data.round + ".citiesPowered";
      let pathNextRound = "playerData." + user + ".rounds." + (data.round + 1);
      let obj = {};
      obj[pathCash] = currentUserData.cash + profit;
      obj[pathCoal] = r.coal - coal;
      obj[pathOil] = r.oil - oil;
      obj[pathGarbage] = r.garbage - garbage;
      obj[pathUranium] = r.uranium - uranium;
      obj[pathRoundPowered] = citiesPowered;
      obj[pathNextRound] = {
        resourcesComplete: false,
        citiesComplete: false,
        bureaucracyComplete: false,
        citiesPowered: 0,
      };

      obj[pathRound] = true;
      dbGame.update(obj).then(() => {
        // see if this is the last player to buy powerplant, if so, move to the next step
        if (tools.lastPlayerToBureaucracy(data, user)) {
          // reorder users
          let newPlayerOrder = tools.updatePlayerOrder(data);
          let gameOver = tools.isGameOver(data);
          // replenish resources
          let resourcesUsed = {
            coal: coal,
            oil: oil,
            garbage: garbage,
            uranium: uranium,
          };
          let newResources = tools.updateResources(data, resourcesUsed);

          // re-arrange powerplants
          let newPowerPlants = tools.updatePowerPlants(data);
          dbGame.set(
            {
              step: "bidP",
              round: data.round + 1,
              playerOrder: newPlayerOrder,
              resources: newResources,
              currentMarket: newPowerPlants.currentMarket,
              futureMarket: newPowerPlants.futureMarket,
              ppDeck: newPowerPlants.ppDeck,
              step3Market: newPowerPlants.step3Market,
              stage: newPowerPlants.stage,
              gameOver: gameOver,
            },
            { merge: true }
          );
        }
        // reset state
        setLivePp([]);
        setPower(0);
        setCitiesPowered(0);
        setProfit(10);
        setCoal(0);
        setOil(0);
        setGarbage(0);
        setUranium(0);
      });
    } else {
      alert("You don't have enough ingredients to run these businesses");
    }
  };

  useEffect(() => {
    if (tools.allPlayersBureaucracied(data)) {
      // reorder users
      let newPlayerOrder = tools.updatePlayerOrder(data);
      let gameOver = tools.isGameOver(data);
      // replenish resources
      let resourcesUsed = {
        coal: coal,
        oil: oil,
        garbage: garbage,
        uranium: uranium,
      };
      let newResources = tools.updateResources(data, resourcesUsed);

      // re-arrange powerplants
      let newPowerPlants = tools.updatePowerPlants(data);
      dbGame.set(
        {
          step: "bidP",
          round: data.round + 1,
          playerOrder: newPlayerOrder,
          resources: newResources,
          currentMarket: newPowerPlants.currentMarket,
          futureMarket: newPowerPlants.futureMarket,
          ppDeck: newPowerPlants.ppDeck,
          step3Market: newPowerPlants.step3Market,
          stage: newPowerPlants.stage,
          gameOver: gameOver,
        },
        { merge: true }
      );
    }
  }, [data]);
  return (
    <React.Fragment>
      <Row className="bureaucracy">
        <Col lg={8}>
          <div className="powerplants">
            <div className="pps">
              {currentUserData.pps.map((plant, index) => {
                return (
                  <div className="bureaucracyPlant" key={index}>
                    <Plant disabled data={plant} />
                    <div className="actions">
                      {!livePp.some((e) => e.id == plant) &&
                        config.powerPlants[plant].type !== "hybrid" &&
                        !currentUserData.rounds[data.round]
                          .bureaucracyComplete &&
                        data.step == "bureaucracy" && (
                          <button
                            onClick={() => {
                              handleToggle(plant, "add");
                            }}
                          >
                            Run Business
                          </button>
                        )}
                      {!livePp.some((e) => e.id == plant) &&
                        config.powerPlants[plant].type == "hybrid" &&
                        !currentUserData.rounds[data.round]
                          .bureaucracyComplete &&
                        data.step == "bureaucracy" && (
                          <React.Fragment>
                            <button
                              onClick={() => {
                                handleToggle(plant, "add", "coal");
                              }}
                            >
                              Run 100% Meat
                            </button>
                            <button
                              onClick={() => {
                                handleToggle(plant, "add", "oil");
                              }}
                            >
                              Run 100% Veggies
                            </button>
                            {config.powerPlants[plant].resourcesNeeded % 2 ==
                              0 && (
                              <button
                                onClick={() => {
                                  handleToggle(plant, "add", "5050");
                                }}
                              >
                                Run 50/50
                              </button>
                            )}
                            {config.powerPlants[plant].resourcesNeeded == 3 && (
                              <>
                                <button
                                  onClick={() => {
                                    handleToggle(plant, "add", "majCoal");
                                  }}
                                >
                                  Run 2/3 Meats
                                </button>
                                <button
                                  onClick={() => {
                                    handleToggle(plant, "add", "majOil");
                                  }}
                                >
                                  Run 2/3 Veggies
                                </button>
                              </>
                            )}
                          </React.Fragment>
                        )}
                      {livePp.some((e) => e.id == plant) &&
                        !currentUserData.rounds[data.round]
                          .bureaucracyComplete &&
                        data.step == "bureaucracy" && (
                          <button
                            onClick={() => {
                              handleToggle(plant, "remove");
                            }}
                          >
                            Undo
                          </button>
                        )}
                      <button
                        className="destroy"
                        onClick={() => {
                          handleDelete(plant);
                        }}
                      >
                        Destroy
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Col>
        <Col lg={4}>
          {data.step == "bureaucracy" && (
            <div className="report">
              <h2>Business Report</h2>
              <div className="info">
                <Row className="served">
                  <Col>
                    <div className="icon">
                      <img src={iconLocation} />
                    </div>
                    <div className="details">Locations served</div>
                  </Col>
                  <Col>
                    <div className="number">{citiesPowered}</div>
                    <div className="potential">Capacity: {power}</div>
                  </Col>
                </Row>
              </div>
              <div className="info">
                <div className="resources">
                  <div className="resource">
                    <img src={iconMeat} /> {coal > 0 && "-"}
                    {coal}
                  </div>
                  <div className="resource">
                    <img src={iconChoi} /> {oil > 0 && "-"}
                    {oil}
                  </div>
                  <div className="resource">
                    <img src={iconTea} /> {garbage > 0 && "-"}
                    {garbage}
                  </div>
                  <div className="resource">
                    <img src={iconTofu} /> {uranium > 0 && "-"}
                    {uranium}
                  </div>
                </div>
              </div>
              {currentUserData.rounds[data.round].bureaucracyComplete &&
                data.step == "bureaucracy" && (
                  <div className="info">Waiting on others</div>
                )}
              {!currentUserData.rounds[data.round].bureaucracyComplete && (
                <div className="info">
                  <Row>
                    <Col>
                      <div className="expected">Expected Revenue</div>
                      <div className="profit">NT${profit}</div>
                    </Col>
                    <Col>
                      {!currentUserData.rounds[data.round]
                        .bureaucracyComplete &&
                        data.step == "bureaucracy" && (
                          <div
                            className="burButton"
                            onClick={() => {
                              handleBureaucracy();
                            }}
                          >
                            吃飯!
                          </div>
                        )}
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="revenueContainer">
          <h4>Revenue Table</h4>
          <table className="revenueTable">
            <thead>
              <tr>
                <th>Number of locations served</th>
                <th>0</th>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
                <th>6</th>
                <th>7</th>
                <th>8</th>
                <th>9</th>
                <th>10</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Revenue (NT$)</td>
                <td>$10</td>
                <td>$22</td>
                <td>$33</td>
                <td>$44</td>
                <td>$54</td>
                <td>$64</td>
                <td>$73</td>
                <td>$82</td>
                <td>$90</td>
                <td>$98</td>
                <td>$105</td>
              </tr>
            </tbody>
          </table>
          <table className="profitTable">
            <thead>
              <tr>
                <th>Number of locations served</th>
                <th>11</th>
                <th>12</th>
                <th>13</th>
                <th>14</th>
                <th>15</th>
                <th>16</th>
                <th>17</th>
                <th>18</th>
                <th>19</th>
                <th>20</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Revenue (NT$)</td>
                <td>$112</td>
                <td>$118</td>
                <td>$124</td>
                <td>$129</td>
                <td>$134</td>
                <td>$138</td>
                <td>$142</td>
                <td>$145</td>
                <td>$148</td>
                <td>$150</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      <Row>
        <Col className="deusEx">
          <div className="manualAdjust">Deus Ex</div>
          <Row>
            <Col>
              Money:{" "}
              <button
                onClick={() => {
                  adjustMoney("add");
                }}
              >
                +
              </button>{" "}
              /{" "}
              <button
                onClick={() => {
                  adjustMoney("subtract");
                }}
              >
                -
              </button>
            </Col>

            <Col>
              Meat:{" "}
              <button
                onClick={() => {
                  adjustResource("coal", "add");
                }}
              >
                +
              </button>{" "}
              /{" "}
              <button
                onClick={() => {
                  adjustResource("coal", "subtract");
                }}
              >
                -
              </button>
            </Col>

            <Col>
              Veggies:{" "}
              <button
                onClick={() => {
                  adjustResource("oil", "add");
                }}
              >
                +
              </button>{" "}
              /{" "}
              <button
                onClick={() => {
                  adjustResource("oil", "subtract");
                }}
              >
                -
              </button>
            </Col>

            <Col>
              Tea:{" "}
              <button
                onClick={() => {
                  adjustResource("garbage", "add");
                }}
              >
                +
              </button>{" "}
              /{" "}
              <button
                onClick={() => {
                  adjustResource("garbage", "subtract");
                }}
              >
                -
              </button>
            </Col>

            <Col>
              Tofu:{" "}
              <button
                onClick={() => {
                  adjustResource("uranium", "add");
                }}
              >
                +
              </button>{" "}
              /{" "}
              <button
                onClick={() => {
                  adjustResource("uranium", "subtract");
                }}
              >
                -
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Bureaucracy;
