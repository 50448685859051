export const powerPlants = {
  1: {
    name: "Cucumbers w/Sauce",
    type: "oil",
    cost: 3,
    resourcesNeeded: 2,
    citiesPowered: 1,
    partner: "711",
  },
  2: {
    name: "Shao Mai",
    type: "coal",
    cost: 4,
    resourcesNeeded: 2,
    citiesPowered: 1,
    partner: "711",
  },
  3: {
    name: "Oyster Omelette",
    type: "hybrid",
    cost: 5,
    resourcesNeeded: 2,
    citiesPowered: 1,
    partner: "711",
  },
  4: {
    name: "Black Milk Tea",
    type: "garbage",
    cost: 6,
    resourcesNeeded: 1,
    citiesPowered: 1,
    partner: "coco",
  },
  5: {
    name: "Scallion Pancakes",
    type: "oil",
    cost: 7,
    resourcesNeeded: 3,
    citiesPowered: 2,
    partner: "tianjin",
  },
  6: {
    name: "Fried Chicken Steak",
    type: "coal",
    cost: 8,
    resourcesNeeded: 3,
    citiesPowered: 2,
    partner: "xxl",
  },
  7: {
    name: "Red Bean Wheel Cake",
    type: "oil",
    cost: 9,
    resourcesNeeded: 1,
    citiesPowered: 1,
    partner: "711",
  },
  8: {
    //name: "Small Sausage Wrapped in Large Sausage",
    name: "大腸包小腸",
    type: "coal",
    cost: 10,
    resourcesNeeded: 2,
    citiesPowered: 2,
    partner: "familymart",
  },
  9: {
    name: "Soy Milk",
    type: "uranium",
    cost: 11,
    resourcesNeeded: 1,
    citiesPowered: 2,
    partner: "familymart",
  },
  10: {
    name: "Gua Bao",
    type: "hybrid",
    cost: 12,
    resourcesNeeded: 2,
    citiesPowered: 2,
    partner: "bao",
  },
  11: {
    name: "Antique Pinball",
    type: "ecological",
    cost: 13,
    resourcesNeeded: 0,
    citiesPowered: 1,
    partner: "usarmy",
  },
  12: {
    name: "Jasmine Grass Jelly Tea",
    type: "garbage",
    cost: 14,
    resourcesNeeded: 2,
    citiesPowered: 2,
    partner: "chatime",
  },
  13: {
    name: "Lu Rou Fan",
    type: "coal",
    cost: 15,
    resourcesNeeded: 2,
    citiesPowered: 3,
    partner: "jinfeng",
  },
  14: {
    name: "Sweet Potato Balls",
    type: "oil",
    cost: 16,
    resourcesNeeded: 2,
    citiesPowered: 3,
    partner: "calbee",
  },
  15: {
    name: "Stinky Tofu",
    type: "uranium",
    cost: 17,
    resourcesNeeded: 1,
    citiesPowered: 2,
    partner: "sunrise",
  },
  16: {
    name: "Expat Musicians",
    type: "ecological",
    cost: 18,
    resourcesNeeded: 0,
    citiesPowered: 2,
    partner: "hippies",
  },
  17: {
    name: "Matcha Milk Tea",
    type: "garbage",
    cost: 19,
    resourcesNeeded: 2,
    citiesPowered: 3,
    partner: "sharetea",
  },
  18: {
    name: "Pork Blood Cake",
    type: "coal",
    cost: 20,
    resourcesNeeded: 3,
    citiesPowered: 5,
    partner: "tt",
  },
  19: {
    name: "Xiao Long Bao",
    type: "hybrid",
    cost: 21,
    resourcesNeeded: 2,
    citiesPowered: 4,
    partner: "din",
  },
  20: {
    name: "Child Drummer",
    type: "ecological",
    cost: 22,
    resourcesNeeded: 0,
    citiesPowered: 2,
    partner: "yamaha",
  },
  21: {
    name: "Doufu Hua",
    type: "uranium",
    cost: 23,
    resourcesNeeded: 1,
    citiesPowered: 3,
    partner: "icemonster",
  },
  22: {
    name: "Taro Milk Tea",
    type: "garbage",
    cost: 24,
    resourcesNeeded: 2,
    citiesPowered: 4,
    partner: "sharetea",
  },
  23: {
    name: "Black Pepper Bun",
    type: "coal",
    cost: 25,
    resourcesNeeded: 2,
    citiesPowered: 5,
    partner: "lkk",
  },
  24: {
    name: "Fried Taro Balls",
    type: "oil",
    cost: 26,
    resourcesNeeded: 2,
    citiesPowered: 5,
    partner: "taro",
  },
  25: {
    name: "Goldfish Scooping",
    type: "ecological",
    cost: 27,
    resourcesNeeded: 0,
    citiesPowered: 3,
    partner: "bandai",
  },
  26: {
    name: "Doufu Hua + Toppings",
    type: "uranium",
    cost: 28,
    resourcesNeeded: 1,
    citiesPowered: 4,
    partner: "icemonster",
  },
  27: {
    name: "Hot Pot",
    type: "hybrid",
    cost: 29,
    resourcesNeeded: 1,
    citiesPowered: 4,
    partner: "mala",
  },
  28: {
    name: "Mango Pomelo Sago",
    type: "garbage",
    cost: 30,
    resourcesNeeded: 3,
    citiesPowered: 6,
    partner: "yifang",
  },
  29: {
    name: "Flame-Grilled Beef",
    type: "coal",
    cost: 31,
    resourcesNeeded: 3,
    citiesPowered: 6,
    partner: "beef",
  },
  30: {
    name: "Veggie Burger",
    type: "oil",
    cost: 32,
    resourcesNeeded: 3,
    citiesPowered: 6,
    partner: "kgb",
  },
  31: {
    name: "Shrimp Fishing",
    type: "ecological",
    cost: 33,
    resourcesNeeded: 0,
    citiesPowered: 4,
    partner: "beer",
  },
  32: {
    name: "Tofu w/Century Egg",
    type: "uranium",
    cost: 34,
    resourcesNeeded: 1,
    citiesPowered: 5,
    partner: "sunrise",
  },
  33: {
    name: "Steamed Egg",
    type: "oil",
    cost: 35,
    resourcesNeeded: 1,
    citiesPowered: 5,
    partner: "cf",
  },
  34: {
    name: "Fresh Seafood",
    type: "coal",
    cost: 36,
    resourcesNeeded: 3,
    citiesPowered: 7,
    partner: "addiction",
  },
  35: {
    name: "Cyber Cafe",
    type: "ecological",
    cost: 37,
    resourcesNeeded: 0,
    citiesPowered: 4,
    partner: "nvidia",
  },
  36: {
    name: "Teahouse",
    type: "garbage",
    cost: 38,
    resourcesNeeded: 3,
    citiesPowered: 7,
    partner: "tourism",
  },
  37: {
    name: "Fresh Soymilk",
    type: "uranium",
    cost: 39,
    resourcesNeeded: 1,
    citiesPowered: 6,
    partner: "soypresso",
  },
  38: {
    name: "Veggie Qingbai Parkfet",
    type: "oil",
    cost: 40,
    resourcesNeeded: 2,
    citiesPowered: 6,
    partner: "buddhist",
  },
  39: {
    name: "Roast Duck",
    type: "coal",
    cost: 42,
    resourcesNeeded: 2,
    citiesPowered: 6,
    partner: "din",
  },
  40: {
    name: "Claw Machines",
    type: "ecological",
    cost: 44,
    resourcesNeeded: 0,
    citiesPowered: 5,
    partner: "sanrio",
  },
  41: {
    name: "Beef Noodle Soup",
    type: "hybrid",
    cost: 46,
    resourcesNeeded: 3,
    citiesPowered: 7,
    partner: "bns",
  },
  42: {
    name: "Shen Yun",
    type: "ecological",
    cost: 50,
    resourcesNeeded: 0,
    citiesPowered: 6,
    partner: "falun",
  },
};

export const map = {
  id: "TPE",
  name: "Taipei",
  width: 3023,
  height: 1956,
  cities: {
    houston: {
      name: "Wanfang Community",
      chinese: "萬芳社區",
      id: "houston",
      top: "0.88",
      left: "0.47",
      section: "red",
      connections: {
        santefe: {
          name: "Daan Park",
          id: "santefe",
          cost: 21,
        },
        dallas: {
          name: "Linguang",
          id: "dallas",
          cost: 5,
        },
        nola: {
          name: "Taipei Zoo",
          id: "nola",
          cost: 8,
        },
      },
    },
    dallas: {
      name: "Linguang",
      chinese: "麟光",
      id: "dallas",
      top: "0.78",
      left: "0.54",
      section: "red",
      connections: {
        santefe: {
          name: "Daan Park",
          id: "santefe",
          cost: 16,
        },
        ok: {
          name: "Taipei 101",
          id: "ok",
          cost: 3,
        },
        houston: {
          name: "Wanfang Community",
          id: "houston",
          cost: 5,
        },
        memphis: {
          name: "Yongchun",
          id: "memphis",
          cost: 12,
        },
        nola: {
          name: "Taipei Zoo",
          id: "nola",
          cost: 12,
        },
      },
    },
    ok: {
      name: "Taipei 101",
      chinese: "台北101",
      id: "ok",
      top: "0.65",
      left: "0.54",
      section: "red",
      connections: {
        santefe: {
          name: "Daan Park",
          id: "santefe",
          cost: 15,
        },
        kansas: {
          name: "Sun Yat-Sen Memorial Hall",
          id: "kansas",
          cost: 8,
        },
        dallas: {
          name: "Linguang",
          id: "dallas",
          cost: 3,
        },
        memphis: {
          name: "Yongchun",
          id: "memphis",
          cost: 14,
        },
      },
    },
    chevenne: {
      name: "Xingtian Temple",
      chinese: "行天宮",
      id: "chevenne",
      top: "0.32",
      left: "0.38",
      section: "purple",
      connections: {
        denver: {
          name: "Songjiang Nanjing",
          id: "denver",
          cost: 0,
        },
        boise: {
          name: "Minquan W",
          id: "boise",
          cost: 24,
        },
        billings: {
          name: "Taipei Fine Arts",
          id: "billings",
          cost: 9,
        },
        min: {
          name: "Songshan Airport",
          id: "min",
          cost: 18,
        },
        omaha: {
          name: "Taipei Arena",
          id: "omaha",
          cost: 14,
        },
      },
    },
    denver: {
      name: "Songjiang Nanjing",
      chinese: "松江南京",
      id: "denver",
      top: "0.44",
      left: "0.37",
      section: "purple",
      connections: {
        santefe: {
          name: "Daan Park",
          id: "santefe",
          cost: 13,
        },
        saltlakecity: {
          name: "Zhongshan",
          id: "saltlakecity",
          cost: 21,
        },
        chevenne: {
          name: "Xingtian Temple",
          id: "chevenne",
          cost: 0,
        },
        kansas: {
          name: "Sun Yat-Sen Memorial Hall",
          id: "kansas",
          cost: 16,
        },
      },
    },
    santefe: {
      name: "Daan Park",
      chinese: "大安公園",
      id: "santefe",
      top: "0.64",
      left: "0.35",
      section: "teal",
      connections: {
        lasvegas: {
          name: "Chiang Kai-shek Memorial Hall",
          id: "lasvegas",
          cost: 27,
        },
        phoenix: {
          name: "National Taiwan University",
          id: "phoenix",
          cost: 18,
        },
        saltlakecity: {
          name: "Zhongshan",
          id: "saltlakecity",
          cost: 28,
        },
        denver: {
          name: "Songjiang Nanjing",
          id: "denver",
          cost: 13,
        },
        kansas: {
          name: "Sun Yat-Sen Memorial Hall",
          id: "kansas",
          cost: 16,
        },
        ok: {
          name: "Taipei 101",
          id: "ok",
          cost: 15,
        },
        dallas: {
          name: "Linguang",
          id: "dallas",
          cost: 16,
        },
        houston: {
          name: "Wanfang Community",
          id: "houston",
          cost: 21,
        },
      },
    },
    phoenix: {
      name: "National Taiwan University",
      chinese: "臺灣大學",
      id: "phoenix",
      top: "0.88",
      left: "0.28",
      section: "teal",
      connections: {
        lasvegas: {
          name: "Chiang Kai-shek Memorial Hall",
          id: "lasvegas",
          cost: 15,
        },
        sandiego: {
          name: "Dingxi",
          id: "sandiego",
          cost: 14,
        },
        santefe: {
          name: "Daan Park",
          id: "santefe",
          cost: 18,
        },
      },
    },
    sandiego: {
      name: "Dingxi",
      chinese: "頂溪",
      id: "sandiego",
      top: "0.85",
      left: "0.15",
      section: "teal",
      connections: {
        lasvegas: {
          name: "Chiang Kai-shek Memorial Hall",
          id: "lasvegas",
          cost: 9,
        },
        losangeles: {
          name: "Huazhong Park",
          id: "losangeles",
          cost: 3,
        },
        phoenix: {
          name: "National Taiwan University",
          id: "phoenix",
          cost: 14,
        },
      },
    },
    saltlakecity: {
      name: "Zhongshan",
      chinese: "中山",
      id: "saltlakecity",
      top: "0.45",
      left: "0.25",
      section: "teal",
      connections: {
        lasvegas: {
          name: "Chiang Kai-shek Memorial Hall",
          id: "lasvegas",
          cost: 18,
        },
        sanfrancisco: {
          name: "Longshan Temple",
          id: "sanfrancisco",
          cost: 27,
        },
        boise: {
          name: "Minquan W",
          id: "boise",
          cost: 8,
        },
        santefe: {
          name: "Daan Park",
          id: "santefe",
          cost: 28,
        },
        denver: {
          name: "Songjiang Nanjing",
          id: "denver",
          cost: 21,
        },
      },
    },
    lasvegas: {
      name: "Chiang Kai-shek Memorial Hall",
      chinese: "中正紀念堂",
      id: "lasvegas",
      top: "0.65",
      left: "0.2",
      section: "teal",
      connections: {
        losangeles: {
          name: "Huazhong Park",
          id: "losangeles",
          cost: 9,
        },
        sanfrancisco: {
          name: "Longshan Temple",
          id: "sanfrancisco",
          cost: 14,
        },
        sandiego: {
          name: "Dingxi",
          id: "sandiego",
          cost: 9,
        },
        phoenix: {
          name: "National Taiwan University",
          id: "phoenix",
          cost: 15,
        },
        santefe: {
          name: "Daan Park",
          id: "santefe",
          cost: 27,
        },
        saltlakecity: {
          name: "Zhongshan",
          id: "saltlakecity",
          cost: 18,
        },
      },
    },
    losangeles: {
      name: "Huazhong Park",
      chinese: "華中河濱公園",
      id: "losangeles",
      top: "0.84",
      left: "0.05",
      section: "teal",
      connections: {
        lasvegas: {
          name: "Chiang Kai-shek Memorial Hall",
          id: "lasvegas",
          cost: 9,
        },
        sanfrancisco: {
          name: "Longshan Temple",
          id: "sanfrancisco",
          cost: 9,
        },
        sandiego: {
          name: "Dingxi",
          id: "sandiego",
          cost: 3,
        },
      },
    },
    sanfrancisco: {
      name: "Longshan Temple",
      chinese: "龍山寺",
      id: "sanfrancisco",
      top: "0.6",
      left: "0.01",
      section: "teal",
      connections: {
        lasvegas: {
          name: "Chiang Kai-shek Memorial Hall",
          id: "lasvegas",
          cost: 14,
        },
        losangeles: {
          name: "Huazhong Park",
          id: "losangeles",
          cost: 9,
        },
        portland: {
          name: "Sanhe",
          id: "portland",
          cost: 24,
        },
        saltlakecity: {
          name: "Zhongshan",
          id: "saltlakecity",
          cost: 27,
        },
        boise: {
          name: "Minquan W",
          id: "boise",
          cost: 23,
        },
      },
    },
    seattle: {
      name: "Sanchong",
      chinese: "三重",
      id: "seattle",
      top: "0.1",
      left: "0.05",
      section: "purple",
      connections: {
        billings: {
          name: "Taipei Fine Arts",
          id: "billings",
          cost: 9,
        },
        boise: {
          name: "Minquan W",
          id: "boise",
          cost: 12,
        },
        portland: {
          name: "Sanhe",
          id: "portland",
          cost: 3,
        },
      },
    },
    portland: {
      name: "Sanhe",
      chinese: "三和",
      id: "portland",
      top: "0.28",
      left: "0.08",
      section: "purple",
      connections: {
        seattle: {
          name: "Sanchong",
          id: "seattle",
          cost: 3,
        },
        boise: {
          name: "Minquan W",
          id: "boise",
          cost: 13,
        },
        sanfrancisco: {
          name: "Longshan Temple",
          id: "sanfrancisco",
          cost: 24,
        },
      },
    },
    boise: {
      name: "Minquan W",
      chinese: "民權西路",
      id: "boise",
      top: "0.3",
      left: "0.18",
      section: "purple",
      connections: {
        seattle: {
          name: "Sanchong",
          id: "seattle",
          cost: 12,
        },
        portland: {
          name: "Sanhe",
          id: "portland",
          cost: 13,
        },
        billings: {
          name: "Taipei Fine Arts",
          id: "billings",
          cost: 12,
        },
        chevenne: {
          name: "Xingtian Temple",
          id: "chevenne",
          cost: 24,
        },
        saltlakecity: {
          name: "Zhongshan",
          id: "saltlakecity",
          cost: 8,
        },
        sanfrancisco: {
          name: "Longshan Temple",
          id: "sanfrancisco",
          cost: 24,
        },
      },
    },
    billings: {
      name: "Taipei Fine Arts",
      chinese: "臺北市立美術館",
      id: "billings",
      top: "0.13",
      left: "0.27",
      section: "purple",
      connections: {
        seattle: {
          name: "Sanchong",
          id: "seattle",
          cost: 9,
        },
        boise: {
          name: "Minquan W",
          id: "boise",
          cost: 12,
        },
        chevenne: {
          name: "Xingtian Temple",
          id: "chevenne",
          cost: 9,
        },
        fargo: {
          name: "Dazhi",
          id: "fargo",
          cost: 17,
        },
        min: {
          name: "Songshan Airport",
          id: "min",
          cost: 18,
        },
      },
    },
    duluth: {
      name: "Miramar Ferris Wheel",
      chinese: "美麗華百樂園",
      id: "duluth",
      top: "0.05",
      left: "0.55",
      section: "yellow",
      connections: {
        fargo: {
          name: "Dazhi",
          id: "fargo",
          cost: 6,
        },
        min: {
          name: "Songshan Airport",
          id: "min",
          cost: 5,
        },
        chicago: {
          name: "Minsheng",
          id: "chicago",
          cost: 12,
        },
        det: {
          name: "Bihu Park",
          id: "det",
          cost: 15,
        },
      },
    },
    fargo: {
      name: "Dazhi",
      chinese: "大直",
      id: "fargo",
      top: "0.03",
      left: "0.45",
      section: "yellow",
      connections: {
        billings: {
          name: "Taipei Fine Arts",
          id: "billings",
          cost: 17,
        },
        min: {
          name: "Songshan Airport",
          id: "min",
          cost: 6,
        },
        duluth: {
          name: "Miramar Ferris Wheel",
          id: "duluth",
          cost: 6,
        },
      },
    },
    min: {
      name: "Songshan Airport",
      chinese: "捷運松山機場",
      id: "min",
      top: "0.20",
      left: "0.48",
      section: "yellow",
      connections: {
        duluth: {
          name: "Miramar Ferris Wheel",
          id: "duluth",
          cost: 5,
        },
        billings: {
          name: "Taipei Fine Arts",
          id: "billings",
          cost: 18,
        },
        fargo: {
          name: "Dazhi",
          id: "fargo",
          cost: 6,
        },
        chevenne: {
          name: "Xingtian Temple",
          id: "chevenne",
          cost: 18,
        },
        omaha: {
          name: "Taipei Arena",
          id: "omaha",
          cost: 8,
        },
        chicago: {
          name: "Minsheng",
          id: "chicago",
          cost: 8,
        },
      },
    },
    omaha: {
      name: "Taipei Arena",
      chinese: "台北小巨蛋",
      id: "omaha",
      top: "0.35",
      left: "0.50",
      section: "purple",
      connections: {
        min: {
          name: "Songshan Airport",
          id: "min",
          cost: 8,
        },
        chevenne: {
          name: "Xingtian Temple",
          id: "chevenne",
          cost: 14,
        },
        kansas: {
          name: "Sun Yat-Sen Memorial Hall",
          id: "kansas",
          cost: 5,
        },
        chicago: {
          name: "Minsheng",
          id: "chicago",
          cost: 13,
        },
      },
    },
    chicago: {
      name: "Minsheng",
      chinese: "民生",
      id: "chicago",
      top: "0.27",
      left: "0.58",
      section: "yellow",
      connections: {
        min: {
          name: "Songshan Airport",
          id: "min",
          cost: 8,
        },
        duluth: {
          name: "Miramar Ferris Wheel",
          id: "duluth",
          cost: 12,
        },
        kansas: {
          name: "Sun Yat-Sen Memorial Hall",
          id: "kansas",
          cost: 8,
        },
        omaha: {
          name: "Taipei Arena",
          id: "omaha",
          cost: 13,
        },
        stlouis: {
          name: "Raohe",
          id: "stlouis",
          cost: 10,
        },
        cinc: {
          name: "Neihu",
          id: "cinc",
          cost: 7,
        },
        det: {
          name: "Bihu Park",
          id: "det",
          cost: 7,
        },
      },
    },
    stlouis: {
      name: "Raohe",
      chinese: "饒河",
      id: "stlouis",
      top: "0.4",
      left: "0.6",
      section: "yellow",
      connections: {
        kansas: {
          name: "Sun Yat-Sen Memorial Hall",
          id: "kansas",
          cost: 6,
        },
        chicago: {
          name: "Minsheng",
          id: "chicago",
          cost: 10,
        },
        memphis: {
          name: "Yongchun",
          id: "memphis",
          cost: 7,
        },
        at: {
          name: "Houshanpi",
          id: "at",
          cost: 12,
        },
        cinc: {
          name: "Neihu",
          id: "cinc",
          cost: 12,
        },
      },
    },
    kansas: {
      name: "Sun Yat-Sen Memorial Hall",
      chinese: "國父紀念館",
      id: "kansas",
      top: "0.5",
      left: "0.52 ",
      section: "red",
      connections: {
        omaha: {
          name: "Taipei Arena",
          id: "omaha",
          cost: 5,
        },
        santefe: {
          name: "Daan Park",
          id: "santefe",
          cost: 16,
        },
        denver: {
          name: "Songjiang Nanjing",
          id: "denver",
          cost: 16,
        },
        ok: {
          name: "Taipei 101",
          id: "ok",
          cost: 8,
        },
        chicago: {
          name: "Minsheng",
          id: "chicago",
          cost: 8,
        },
        stlouis: {
          name: "Raohe",
          id: "stlouis",
          cost: 6,
        },
        memphis: {
          name: "Yongchun",
          id: "memphis",
          cost: 12,
        },
      },
    },
    memphis: {
      name: "Yongchun",
      chinese: "永春",
      id: "memphis",
      top: "0.54",
      left: "0.63",
      section: "red",
      connections: {
        kansas: {
          name: "Sun Yat-Sen Memorial Hall",
          id: "kansas",
          cost: 12,
        },
        ok: {
          name: "Taipei 101",
          id: "ok",
          cost: 14,
        },
        stlouis: {
          name: "Raohe",
          id: "stlouis",
          cost: 7,
        },
        dallas: {
          name: "Linguang",
          id: "dallas",
          cost: 12,
        },
        nola: {
          name: "Taipei Zoo",
          id: "nola",
          cost: 7,
        },
        bir: {
          name: "Tiger Mountain",
          id: "bir",
          cost: 6,
        },
      },
    },
    nola: {
      name: "Taipei Zoo",
      chinese: "動物園",
      id: "nola",
      top: "0.9",
      left: "0.64",
      section: "red",
      connections: {
        memphis: {
          name: "Yongchun",
          id: "memphis",
          cost: 7,
        },
        houston: {
          name: "Wanfang Community",
          id: "houston",
          cost: 8,
        },
        dallas: {
          name: "Linguang",
          id: "dallas",
          cost: 12,
        },
        bir: {
          name: "Tiger Mountain",
          id: "bir",
          cost: 11,
        },
        jack: {
          name: "C.U.S.T.",
          id: "jack",
          cost: 16,
        },
      },
    },
    bir: {
      name: "Tiger Mountain",
      chinese: "虎山",
      id: "bir",
      top: "0.65",
      left: "0.72",
      section: "red",
      connections: {
        memphis: {
          name: "Yongchun",
          id: "memphis",
          cost: 6,
        },
        nola: {
          name: "Taipei Zoo",
          id: "nola",
          cost: 11,
        },
        at: {
          name: "Houshanpi",
          id: "at",
          cost: 3,
        },
        jack: {
          name: "C.U.S.T.",
          id: "jack",
          cost: 9,
        },
      },
    },
    at: {
      name: "Houshanpi",
      chinese: "後山埤",
      id: "at",
      top: "0.47",
      left: "0.75",
      section: "green",
      connections: {
        bir: {
          name: "Tiger Mountain",
          id: "bir",
          cost: 3,
        },
        stlouis: {
          name: "Raohe",
          id: "stlouis",
          cost: 12,
        },
        kx: {
          name: "Tanmei Park",
          id: "kx",
          cost: 5,
        },
        ray: {
          name: "Kunyang",
          id: "ray",
          cost: 7,
        },
        sav: {
          name: "Academia Park",
          id: "sav",
          cost: 7,
        },
      },
    },
    kx: {
      name: "Tanmei Park",
      chinese: "潭美公園",
      id: "kx",
      top: "0.34",
      left: "0.72",
      section: "yellow",
      connections: {
        at: {
          name: "Houshanpi",
          id: "at",
          cost: 5,
        },
        cinc: {
          name: "Neihu",
          id: "cinc",
          cost: 6,
        },
      },
    },
    cinc: {
      name: "Neihu",
      chinese: "內湖",
      id: "cinc",
      top: "0.20",
      left: "0.69",
      section: "yellow",
      connections: {
        kx: {
          name: "Tanmei Park",
          id: "kx",
          cost: 6,
        },
        stlouis: {
          name: "Raohe",
          id: "stlouis",
          cost: 12,
        },
        chicago: {
          name: "Minsheng",
          id: "chicago",
          cost: 7,
        },
        det: {
          name: "Bihu Park",
          id: "det",
          cost: 4,
        },
        pitt: {
          name: "Shitan Park",
          id: "pitt",
          cost: 7,
        },
        ray: {
          name: "Kunyang",
          id: "ray",
          cost: 15,
        },
      },
    },
    det: {
      name: "Bihu Park",
      chinese: "碧湖公園",
      id: "det",
      top: "0.05",
      left: "0.7",
      section: "orange",
      connections: {
        cinc: {
          name: "Neihu",
          id: "cinc",
          cost: 4,
        },
        chicago: {
          name: "Minsheng",
          id: "chicago",
          cost: 7,
        },
        duluth: {
          name: "Miramar Ferris Wheel",
          id: "duluth",
          cost: 15,
        },
        buf: {
          name: "Qingbai Park",
          id: "buf",
          cost: 7,
        },
        pitt: {
          name: "Shitan Park",
          id: "pitt",
          cost: 6,
        },
      },
    },
    buf: {
      name: "Qingbai Park",
      chinese: "清白公園",
      id: "buf",
      top: "0.005",
      left: "0.78",
      section: "orange",
      connections: {
        det: {
          name: "Bihu Park",
          id: "det",
          cost: 7,
        },
        pitt: {
          name: "Shitan Park",
          id: "pitt",
          cost: 7,
        },
        nyc: {
          name: "Huzhou",
          id: "nyc",
          cost: 8,
        },
      },
    },
    pitt: {
      name: "Shitan Park",
      chinese: "石潭公園",
      id: "pitt",
      top: "0.26",
      left: "0.79",
      section: "orange",
      connections: {
        buf: {
          name: "Qingbai Park",
          id: "buf",
          cost: 7,
        },
        det: {
          name: "Bihu Park",
          id: "det",
          cost: 6,
        },
        cinc: {
          name: "Neihu",
          id: "cinc",
          cost: 7,
        },
        wash: {
          name: "Tanmeimaobaobeikuaile Park",
          id: "wash",
          cost: 6,
        },
        ray: {
          name: "Kunyang",
          id: "ray",
          cost: 7,
        },
      },
    },
    wash: {
      name: "Tanmeimaobao-beikuaile Park",
      chinese: "潭美毛寶貝快樂公園",
      id: "wash",
      top: "0.25",
      left: "0.89",
      section: "orange",
      connections: {
        pitt: {
          name: "Shitan Park",
          id: "pitt",
          cost: 6,
        },
        phil: {
          name: "Donghu",
          id: "phil",
          cost: 3,
        },
        nor: {
          name: "Taipei Nangang Exhibition Center",
          id: "nor",
          cost: 5,
        },
      },
    },
    phil: {
      name: "Donghu",
      chinese: "捷運東湖",
      id: "phil",
      top: "0.15",
      left: "0.95",
      section: "orange",
      connections: {
        wash: {
          name: "Tanmeimaobaobeikuaile Park",
          id: "wash",
          cost: 3,
        },
        nyc: {
          name: "Huzhou",
          id: "nyc",
          cost: 0,
        },
      },
    },
    nyc: {
      name: "Huzhou",
      chinese: "捷運葫洲",
      id: "nyc",
      top: "0.14",
      left: "0.86",
      section: "orange",
      connections: {
        phil: {
          name: "Donghu",
          id: "phil",
          cost: 0,
        },
        buf: {
          name: "Qingbai Park",
          id: "buf",
          cost: 8,
        },
        boston: {
          name: "Dahu Park",
          id: "boston",
          cost: 3,
        },
      },
    },
    boston: {
      name: "Dahu Park",
      chinese: "大湖公園",
      id: "boston",
      top: "0.02",
      left: "0.92",
      section: "orange",
      connections: {
        nyc: {
          name: "Huzhou",
          id: "nyc",
          cost: 3,
        },
      },
    },
    nor: {
      name: "Taipei Nangang Exhibition Center",
      chinese: "捷運南港展覽館",
      id: "nor",
      top: "0.38",
      left: "0.93",
      section: "green",
      connections: {
        wash: {
          name: "Tanmeimaobaobeikuaile Park",
          id: "wash",
          cost: 5,
        },
        ray: {
          name: "Kunyang",
          id: "ray",
          cost: 3,
        },
      },
    },
    ray: {
      name: "Kunyang",
      chinese: "昆陽",
      id: "ray",
      top: "0.44",
      left: "0.85",
      section: "green",
      connections: {
        nor: {
          name: "Taipei Nangang Exhibition Center",
          id: "nor",
          cost: 3,
        },
        pitt: {
          name: "Shitan Park",
          id: "pitt",
          cost: 7,
        },
        cinc: {
          name: "Neihu",
          id: "cinc",
          cost: 15,
        },
        at: {
          name: "Houshanpi",
          id: "at",
          cost: 7,
        },
        sav: {
          name: "Academia Park",
          id: "sav",
          cost: 7,
        },
      },
    },
    sav: {
      name: "Academia Park",
      chinese: "中研公園",
      id: "sav",
      top: "0.55",
      left: "0.9",
      section: "green",
      connections: {
        ray: {
          name: "Kunyang",
          id: "ray",
          cost: 7,
        },
        at: {
          name: "Houshanpi",
          id: "at",
          cost: 7,
        },
        jack: {
          name: "C.U.S.T.",
          id: "jack",
          cost: 0,
        },
      },
    },
    jack: {
      name: "C.U.S.T.",
      chinese: "中華科技大學",
      id: "jack",
      top: "0.65",
      left: "0.92",
      section: "green",
      connections: {
        sav: {
          name: "Academia Park",
          id: "sav",
          cost: 0,
        },
        bir: {
          name: "Tiger Mountain",
          id: "bir",
          cost: 9,
        },
        nola: {
          name: "Taipei Zoo",
          id: "nola",
          cost: 16,
        },
        tamp: {
          name: "Shanshuilu Eco Park",
          id: "tamp",
          cost: 4,
        },
      },
    },
    tamp: {
      name: "Shanshuilu Eco Park",
      chinese: "山水綠生態公園",
      id: "tamp",
      top: "0.8",
      left: "0.93",
      section: "green",
      connections: {
        jack: {
          name: "C.U.S.T.",
          id: "jack",
          cost: 4,
        },
        miami: {
          name: "Muzhishan",
          id: "miami",
          cost: 4,
        },
      },
    },
    miami: {
      name: "Muzhishan",
      chinese: "拇指山",
      id: "miami",
      top: "0.9",
      left: "0.78",
      section: "green",
      connections: {
        tamp: {
          name: "Shanshuilu Eco Park",
          id: "tamp",
          cost: 4,
        },
      },
    },
  },
};

export const resourceUpdate = {
  2: {
    1: {
      coal: 3,
      oil: 2,
      garbage: 1,
      uranium: 1,
    },
    2: {
      coal: 4,
      oil: 2,
      garbage: 2,
      uranium: 1,
    },
    3: {
      coal: 3,
      oil: 4,
      garbage: 3,
      uranium: 1,
    },
  },
  3: {
    1: {
      coal: 4,
      oil: 2,
      garbage: 1,
      uranium: 1,
    },
    2: {
      coal: 5,
      oil: 3,
      garbage: 2,
      uranium: 1,
    },
    3: {
      coal: 3,
      oil: 4,
      garbage: 3,
      uranium: 1,
    },
  },
  4: {
    1: {
      coal: 5,
      oil: 3,
      garbage: 2,
      uranium: 1,
    },
    2: {
      coal: 6,
      oil: 4,
      garbage: 3,
      uranium: 2,
    },
    3: {
      coal: 4,
      oil: 5,
      garbage: 4,
      uranium: 2,
    },
  },
  5: {
    1: {
      coal: 5,
      oil: 4,
      garbage: 3,
      uranium: 2,
    },
    2: {
      coal: 7,
      oil: 5,
      garbage: 3,
      uranium: 3,
    },
    3: {
      coal: 5,
      oil: 6,
      garbage: 5,
      uranium: 2,
    },
  },
  6: {
    1: {
      coal: 7,
      oil: 5,
      garbage: 3,
      uranium: 2,
    },
    2: {
      coal: 9,
      oil: 6,
      garbage: 5,
      uranium: 3,
    },
    3: {
      coal: 6,
      oil: 7,
      garbage: 6,
      uranium: 3,
    },
  },
};
