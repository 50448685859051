import React, { useState, useEffect } from "react";
import firebase from "../Firebase/Firestore";
import * as tools from "../functions";
import * as config from "../config";
import _ from "lodash";
import { Row, Col } from "react-bootstrap";

const Setup = (props) => {
  const [newPlayerName, setNewPlayerName] = useState("");
  const [newPlayerColor, setNewPlayerColor] = useState("");
  const data = props.data;
  const dbGame = props.db.collection("games").doc(data.id);
  const [added, setAdded] = useState(false);
  const [alertShown, setAlertShown] = useState(false);

  const handleNewPlayerName = (e) => {
    setNewPlayerName(e.target.value);
  };

  const handleNewPlayerColor = (e) => {
    setNewPlayerColor(e.target.value);
  };

  const handleNewPlayer = (e) => {
    e.preventDefault();
    if (newPlayerName && newPlayerColor) {
      let nameAlreadyExists = false;
      let playerNames = [];
      if (data.playerData) {
        playerNames = Object.keys(data.playerData);
      }
      if (data.playerData) {
        for (var i = 0; i < playerNames.length; i++) {
          if (data.playerData[playerNames[i]].color == newPlayerColor) {
            nameAlreadyExists = true;
          }
        }
      }
      if (!nameAlreadyExists) {
        let usersUpdate = {};
        usersUpdate[`playerData.${newPlayerName}`] = {
          name: newPlayerName,
          color: newPlayerColor,
        };
        dbGame.update(usersUpdate, { merge: true });
        props.setUser(newPlayerName);
        setAdded(true);
      } else {
        alert("Sorry, this color has already been claimed.");
      }
    }
  };

  const handleLaunch = () => {
    let playerDataTemp = _.cloneDeep(data.playerData);

    for (const [key, value] of Object.entries(playerDataTemp)) {
      // setup each player
      playerDataTemp[key].cash = 50;
      playerDataTemp[key].houses = 20;
      playerDataTemp[key].pps = [];
      playerDataTemp[key].rounds = {
        1: {},
      };
      playerDataTemp[key].resources = {
        coal: 0,
        oil: 0,
        garbage: 0,
        uranium: 0,
      };
      playerDataTemp[key].cities = [];
    }

    // determine first player order
    let playerOrder = [];
    for (const [key, value] of Object.entries(playerDataTemp)) {
      playerOrder.push(playerDataTemp[key].name);
    }
    tools.shuffle(playerOrder);

    let resources = {
      coal: [],
      oil: [],
      garbage: [],
      uranium: [],
    };

    resources.coal = tools.fillCoalOilGarbage(resources.coal, 24);
    resources.oil = tools.fillCoalOilGarbage(resources.oil, 18);
    resources.garbage = tools.fillCoalOilGarbage(resources.garbage, 9);
    resources.uranium = tools.fillUranium(resources.uranium, 2);

    let marketSetup = tools.setupMarket(data.numberOfPlayers);

    let disabledSections = [];
    let sectionsArray = ["purple", "teal", "red", "yellow", "green", "orange"];
    tools.shuffle(sectionsArray);
    let dice = 1;
    console.log("LETS DISABLE AREAS");
    console.log(data.numberOfPlayers);
    if (data.numberOfPlayers == 2) {
      dice = Math.floor(Math.random() * 4) + 1;
      if (dice == 1) {
        disabledSections = ["orange", "green", "red"];
      } else if (dice == 2) {
        disabledSections = ["green", "red", "teal"];
      } else if (dice == 3) {
        disabledSections = ["teal", "purple", "yellow"];
      } else if (dice == 4) {
        disabledSections = ["orange", "purple", "yellow"];
      }
    } else if (data.numberOfPlayers == 3) {
      console.log("3 players detected");
      dice = Math.floor(Math.random() * 4) + 1;
      console.log(dice);
      if (dice == 1) {
        disabledSections = ["orange", "green", "red"];
      } else if (dice == 2) {
        disabledSections = ["green", "red", "teal"];
      } else if (dice == 3) {
        disabledSections = ["teal", "purple", "yellow"];
      } else if (dice == 4) {
        disabledSections = ["orange", "purple", "yellow"];
      }
      console.log(disabledSections);
    } else if (data.numberOfPlayers == 4) {
      dice = Math.floor(Math.random() * 8) + 1;
      if (dice == 1) {
        disabledSections = ["teal", "purple"];
      } else if (dice == 2) {
        disabledSections = ["purple", "yellow"];
      } else if (dice == 3) {
        disabledSections = ["yellow", "orange"];
      } else if (dice == 4) {
        disabledSections = ["orange", "green"];
      } else if (dice == 5) {
        disabledSections = ["green", "red"];
      } else if (dice == 6) {
        disabledSections = ["red", "teal"];
      } else if (dice == 7) {
        disabledSections = ["purple", "green"];
      } else if (dice == 8) {
        disabledSections = ["teal", "orange"];
      }
    } else if (data.numberOfPlayers == 5) {
      disabledSections.push(sectionsArray[0]);
    } else if (data.numberOfPlayers == 6) {
      disabledSections.push(sectionsArray[0]);
    }

    dbGame.set(
      {
        started: true,
        round: 1,
        mode: "active",
        step: "bidP",
        stage: 1,
        ppDeck: marketSetup.ppDeck,
        currentMarket: marketSetup.currentMarket,
        futureMarket: marketSetup.futureMarket,
        step3Market: [],
        disabledSections: disabledSections,
        resources: resources,
        playerData: playerDataTemp,
        playerOrder: playerOrder,
        currentPlayer: playerOrder[playerOrder.length - 1],
      },
      { merge: true }
    );
  };

  const launchGameButton = <button onClick={handleLaunch}>Launch Game</button>;

  let playerNames = [];
  if (data?.playerData) {
    playerNames = Object.keys(data.playerData);
  }

  // see what colors are available
  let availableColors = [
    "Red",
    "Blue",
    "Yellow",
    "Green",
    "Orange",
    "Pink",
    "Purple",
  ];
  if (data?.playerData) {
    for (var i = 0; i < playerNames.length; i++) {
      // remove each player color
      const index = availableColors.indexOf(
        data.playerData[playerNames[i]].color
      );
      if (index > -1) {
        availableColors.splice(index, 1);
      }
    }
  }
  if (!availableColors.includes(newPlayerColor)) {
    if (!props.user) {
      if (newPlayerColor !== "") {
        setNewPlayerColor("");
      }
    }
  }

  return (
    <div className="setup">
      <Row>
        <Col>
          <h1>Game Setup</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="currentPlayers">
            <h2>Current Players</h2>
            <div className="setupPlayers">
              {playerNames.map((playerName, index) => {
                let player = data.playerData[playerName];
                return (
                  <div className="player" key={index}>
                    <div className={"icon icon" + player.color}></div>
                    <div className="playerName"> {player.name}</div>
                    {!props.user && (
                      <button
                        onClick={() => {
                          props.setUser(player.name);
                        }}
                      >
                        Claim
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="totalJoined">
              {playerNames.length} / {data.numberOfPlayers} players have joined
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="addYourself">
            {playerNames.length == data.numberOfPlayers &&
              !data.started &&
              props.user &&
              launchGameButton}
            {playerNames.length < data.numberOfPlayers &&
              !data.started &&
              !props.user && (
                <div>
                  <h2>Add yourself</h2>
                  <form onSubmit={handleNewPlayer}>
                    <input
                      onChange={handleNewPlayerName}
                      type="text"
                      name="playerName"
                      placeholder="Your name"
                      value={newPlayerName}
                    />
                    <div className="selectColor">
                      <select
                        id="colors"
                        name="colors"
                        onChange={handleNewPlayerColor}
                        value={newPlayerColor}
                      >
                        <option value="">Choose a color...</option>
                        {availableColors.map((color, index) => {
                          return (
                            <option key={index} value={color}>
                              {color}
                            </option>
                          );
                        })}
                      </select>
                      <button type="submit">Submit</button>
                    </div>
                  </form>
                </div>
              )}
            {playerNames.length < data.numberOfPlayers &&
              !data.started &&
              props.user &&
              "Waiting for all players to join..."}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Setup;
