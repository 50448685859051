import React, { useState, useEffect } from "react";
import iconStage from "../assets/images/icon-stage.png";
import iconRound from "../assets/images/icon-turn.png";
var giphy = require("giphy-api")({
  https: true,
  apiKey: "fFNOmBxS5jLIAZYvClDgXiFNW0Hd8an5",
});

const StepTransition = (props) => {
  const [step, setStep] = useState("");
  const [phase, setPhase] = useState("");
  const [image, setImage] = useState("");
  const [tabName, setTabName] = useState("");

  const data = props.data;

  const handleClick = (tab) => {
    props.showTransition(false);
    props.setActiveTab(tab);
  };

  useEffect(() => {
    // set steps
    if (data.step == "bidP") {
      setPhase("bid for contracts");
      setTabName("pp");
      giphy.search({ q: "auction", limit: 99 }).then(function (res) {
        let randomNumber = Math.floor(Math.random() * res.data.length);
        let url = res.data[randomNumber].images.original.mp4;
        url = url.split("?")[0];
        setImage(url);
      });
    } else if (data.step == "resources") {
      setPhase("buy ingredients");
      setTabName("resources");
      giphy.search({ q: "grocery shopping", limit: 99 }).then(function (res) {
        let randomNumber = Math.floor(Math.random() * res.data.length);
        let url = res.data[randomNumber].images.original.mp4;
        url = url.split("?")[0];
        setImage(url);
      });
    } else if (data.step == "map") {
      setPhase("buy locations");
      setTabName("map");
      giphy.search({ q: "map", limit: 99 }).then(function (res) {
        let randomNumber = Math.floor(Math.random() * res.data.length);
        let url = res.data[randomNumber].images.original.mp4;
        url = url.split("?")[0];
        setImage(url);
      });
    } else if (data.step == "bureaucracy") {
      setPhase("吃飯");
      setTabName("bureaucracy");
      giphy.search({ q: "money", limit: 99 }).then(function (res) {
        let randomNumber = Math.floor(Math.random() * res.data.length);
        let url = res.data[randomNumber].images.original.mp4;
        url = url.split("?")[0];
        setImage(url);
      });
    }
    setStep(data.stage);
  }, [props]);

  return (
    <div className="stepTransition fade-in">
      <div className="infoBox">
        <div className="gif">
          <video autoPlay muted loop src={image}></video>
        </div>
        <div className="stageRound">
          <div className="stageRoundWrapper">
            <div className="stage">
              <img src={iconStage} /> {data.stage}
            </div>
            <div className="round">
              <img src={iconRound} /> {data.round}
            </div>
          </div>
        </div>
        <h1>
          Time to <strong>{phase}</strong>!
        </h1>
        <div
          className="button primary"
          onClick={() => {
            handleClick(tabName);
          }}
        >
          Let's go!
        </div>
      </div>
    </div>
  );
};

export default StepTransition;
