import React, { useState, useEffect } from "react";
import City from "../Components/City";
import ConnectionPipe from "../Components/ConnectionPipe";
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { MapInteractionCSS } from "react-map-interaction";
import mainMapImage from "../assets/images/taipei.jpeg";
import { map } from "../config";
import * as config from "../config";
import * as tools from "../functions";
import _ from "lodash";

const Map = (props) => {
  const generatedMap = tools.generateMap(this);
  const [activeUser, setActiveUser] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [cityCost, setCityCost] = useState();
  const [buyMsg, setBuyMsg] = useState("");
  const [mapSettings, setMapSettings] = useState({
    scale: 0.6,
    translation: { x: 0, y: -100 },
  });

  const data = props.data;
  const user = props.user;
  const playerData = data.playerData;
  const userCities = playerData[user].cities;
  const dbGame = props.db.collection("games").doc(data.id);

  const handleCityClick = (city) => {
    if (activeUser == user && data.step == "map") {
      // check if you can buy it
      if (cityCost !== null) {
        // calculate the cost
        let cost = tools.lowestCostOfBuying(
          playerData,
          userCities,
          selectedCity
        );
        // check if you can afford it
        if (cost <= playerData[user].cash) {
          // buy and update user
          let updatedCash = playerData[user].cash - cost;
          let newCityArray = _.cloneDeep(userCities);
          newCityArray.push(selectedCity);
          let pathCities = "playerData." + user + ".cities";
          let pathCash = "playerData." + user + ".cash";
          let obj = {};
          obj[pathCities] = newCityArray;
          obj[pathCash] = updatedCash;
          dbGame.update(obj);
        } else {
          alert("You can't afford this city.");
        }
        // buy it
      }
    }
  };

  const handleEndTurn = () => {
    let pathRound =
      "playerData." + user + ".rounds." + data.round + ".citiesComplete";
    let obj = {};
    obj[pathRound] = true;
    obj["currentPlayer"] = tools.whoseTurn(data, "citiesComplete");
    dbGame.update(obj).then(() => {
      // see if this is the last player to buy cities, if so, move to the next step
      if (tools.lastPlayerToBuyCities(data, user)) {
        // check if we need to move to step 2
        let usersArray = Object.keys(data.playerData);
        let goToStep2 = false;
        let highestNumberOfCities = 0;
        usersArray.forEach((player) => {
          // determine highest
          if (data.playerData[player].cities.length > highestNumberOfCities) {
            highestNumberOfCities = data.playerData[player].cities.length;
          }
          // check for step 2 trigger
          if (data.numberOfPlayers == 6) {
            if (data.playerData[player].cities.length > 5) {
              goToStep2 = true;
            }
          } else {
            if (data.playerData[player].cities.length > 6) {
              goToStep2 = true;
            }
          }
        });
        let newStage = data.stage;
        if (goToStep2) {
          if (data.stage == 1) {
            newStage = 2;
          }
        }

        let newCurrentMarket = data.currentMarket;
        let newFutureMarket = data.futureMarket;
        let newPpDeck = data.ppDeck;
        let newStep3Market = data.step3Market;
        // re-arrange power plants if there are plants smaller than the highest city number
        let newPpSet = tools.updatePowerPlantsByHighestCity(
          data,
          highestNumberOfCities
        );

        newCurrentMarket = newPpSet.currentMarket;
        newFutureMarket = newPpSet.futureMarket;
        newPpDeck = newPpSet.ppDeck;
        newStep3Market = newPpSet.step3Market;

        dbGame.set(
          {
            step: "bureaucracy",
            stage: newStage,
            currentMarket: newCurrentMarket,
            futureMarket: newFutureMarket,
            ppDeck: newPpDeck,
            step3Market: newStep3Market,
          },
          { merge: true }
        );
      }
    });
  };

  const handleSelect = (city) => {
    setSelectedCity(city);
  };

  const handleUnselect = () => {
    console.log("unselected");
  };

  useEffect(() => {
    // first see if house is purchasable
    // do you own the house
    if (userCities.includes(selectedCity)) {
      setCityCost(null);
      setBuyMsg("Already owned");
      // is it full?
    } else if (tools.isCityFull(playerData, selectedCity, data.stage)) {
      setCityCost(null);
      setBuyMsg("City is full");
      // are you connected to it?
    } else if (
      !tools.isCityConnected(userCities, selectedCity) &&
      userCities.length > 0
    ) {
      setCityCost(null);
      setBuyMsg("No existing connections");
      // ok let's find out the best
    } else {
      let cost = tools.lowestCostOfBuying(playerData, userCities, selectedCity);
      setCityCost(cost);
      if (activeUser == user && data.step == "map") {
        setBuyMsg("Click to buy");
      } else {
        setBuyMsg("Buy on your turn");
      }
    }
  }, [selectedCity, data]);

  useEffect(() => {
    setActiveUser(tools.whoseTurn(data, "citiesComplete"));
  }, [data]);

  const cities = generatedMap.citiesArray.map((city, index) => {
    let disabled = false;
    if (data.disabledSections.includes(city.section)) {
      disabled = true;
    }
    return (
      <City
        key={index}
        name={city.name}
        chinese={city.chinese}
        id={city.id}
        top={city.top}
        left={city.left}
        data={props.data}
        handleClick={handleCityClick}
        handleSelect={handleSelect}
        handleUnselect={handleUnselect}
        disabled={disabled}
      />
    );
  });

  // Pipe Connections
  const allConnections = generatedMap.uniqueConnectionsArray.map(
    (connection, index) => {
      let disabled = false;

      if (
        data.disabledSections.includes(
          config.map.cities[connection.startCity].section
        ) ||
        data.disabledSections.includes(
          config.map.cities[connection.endCity].section
        )
      ) {
        disabled = true;
      }

      return (
        <ConnectionPipe
          key={index}
          startCity={connection.startCity}
          endCity={connection.endCity}
          cost={connection.cost}
          disabled={disabled}
        />
      );
    }
  );
  return (
    <React.Fragment>
      <div className="buyBox">
        <div className="top">
          <div className="selectedCity">
            <div className="nameEnglish">
              {selectedCity && map.cities[selectedCity].name}{" "}
            </div>
            <div className="nameChinese">
              {selectedCity && map.cities[selectedCity].chinese}
            </div>
            {!selectedCity && "Hover over a location to select"}
          </div>
          {selectedCity && (
            <div className="cost">{cityCost && "$" + cityCost}</div>
          )}
          {selectedCity && <div className="buyMsg">{buyMsg}</div>}
        </div>
        <div className="bottom">
          {activeUser == user && data.step == "map" && (
            <button onClick={handleEndTurn}>I'm done buying</button>
          )}
          {activeUser !== user && data.step == "map" && (
            <div className="waiting">Waiting on {activeUser}...</div>
          )}
        </div>
      </div>
      <MapInteractionCSS
        value={mapSettings}
        onChange={(value) => {
          setMapSettings(value);
        }}
      >
        <div className="mapContainer">
          <div className="connections">
            <div className="connectionPipesContainer">{allConnections} </div>
          </div>
          <div className="mainMap">
            {cities}
            <img
              className="mainMapImage"
              alt="Map of US Background"
              src={mainMapImage}
            />
          </div>
        </div>
      </MapInteractionCSS>
    </React.Fragment>
  );
};

export default Map;
