import React, { useState, useEffect } from "react";
import firebase from "../Firebase/Firestore";
import * as tools from "../functions";
import * as config from "../config";
import Map from "../Pages/Map";
import _ from "lodash";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import Plant from "./Plant";

const PowerPlants = (props) => {
  const data = props.data;
  const user = props.user;

  const dbGame = props.db.collection("games").doc(data.id);
  const [selectedPp, setSelectedPp] = useState("");
  const [buyPrice, setBuyPrice] = useState(0);

  const handleSelect = (plant) => {
    if (data.step == "bidP") {
      setSelectedPp(plant);
    } else {
      alert("Not currently in bidding phase");
    }
  };

  const handleMoneyInput = (e) => {
    setBuyPrice(e.target.value);
  };

  const handleSkip = () => {
    let pathBought =
      "playerData." + user + ".rounds." + data.round + ".ppBought";
    let pathRound =
      "playerData." + user + ".rounds." + data.round + ".ppComplete";
    let obj = {};
    obj[pathRound] = true;
    obj[pathBought] = false;
    obj["currentPlayer"] = tools.whoseTurnBidP(data, "ppComplete");
    dbGame.update(obj);
    // see if this is the last player to buy powerplant, if so, move to the next step
    let newStage = data.stage;
    let newCurrentMarket = data.currentMarket;
    let newFutureMarket = data.futureMarket;
    let newPpDeck = data.ppDeck;
    let newStep3Market = data.step3Market;

    if (tools.lastPlayerToBuyPlant(data, user)) {
      if (data.stage !== 3 && data.futureMarket.length < 4) {
        alert("Rearranging partnerships for Step 3");
        newStage = 3;
        let newState = tools.setupStep3Pps(data);
        newCurrentMarket = newState.currentMarket;
        newFutureMarket = newState.futureMarket;
      } else {
        // check to see if we need to remove the lowest card because no one bought
        let playersArray = Object.keys(data.playerData);
        let allSkippedBuying = true;
        playersArray.forEach((player) => {
          if (data.playerData[player].rounds[data.round].ppBought) {
            allSkippedBuying = false;
          }
        });
        if (allSkippedBuying) {
          let skippedPps = {};
          if (data.stage < 3) {
            skippedPps = tools.removePowerplant(
              data.currentMarket[0],
              data.currentMarket,
              data.futureMarket,
              data.ppDeck,
              data.step3Market
            );
          } else if (data.stage == 3) {
            skippedPps = tools.removePowerplantStep3(
              data.currentMarket[0],
              data.currentMarket,
              data.futureMarket,
              data.ppDeck,
              data.step3Market
            );
          }
          newCurrentMarket = skippedPps.currentMarket;
          newFutureMarket = skippedPps.futureMarket;
          newPpDeck = skippedPps.ppDeck;
          newStep3Market = skippedPps.step3Market;
        }
      }

      dbGame.set(
        {
          step: "resources",
          stage: newStage,
          currentMarket: newCurrentMarket,
          futureMarket: newFutureMarket,
          ppDeck: newPpDeck,
          step3Market: newStep3Market,
        },
        { merge: true }
      );
    }
    // reset state
    setSelectedPp("");
    setBuyPrice(0);
  };

  const handleBuy = () => {
    if (selectedPp && buyPrice) {
      const userData = data.playerData[user];
      if (userData.cash >= buyPrice) {
        if (userData.pps.length < 3) {
          let updatedCash = userData.cash - buyPrice; // remove money from user
          // add powerpolant to existing player
          let pps = _.cloneDeep(userData.pps);
          pps.push(selectedPp);
          let pathPps = "playerData." + user + ".pps";
          let pathCash = "playerData." + user + ".cash";
          let pathRound =
            "playerData." + user + ".rounds." + data.round + ".ppComplete";
          let pathBought =
            "playerData." + user + ".rounds." + data.round + ".ppBought";
          let obj = {};
          obj[pathPps] = pps;
          obj[pathCash] = updatedCash;
          obj[pathRound] = true;
          obj[pathBought] = true;
          obj["currentPlayer"] = tools.whoseTurnBidP(data, "ppComplete");
          dbGame.update(obj).then(() => {
            let updatedPps = {};

            if (data.stage < 3) {
              updatedPps = tools.removePowerplant(
                selectedPp,
                data.currentMarket,
                data.futureMarket,
                data.ppDeck,
                data.step3Market
              );
            } else {
              updatedPps = tools.removePowerplantStep3(
                selectedPp,
                data.currentMarket,
                data.futureMarket,
                data.ppDeck,
                data.step3Market
              );
            }

            dbGame
              .set(
                {
                  currentMarket: updatedPps.currentMarket,
                  futureMarket: updatedPps.futureMarket,
                  ppDeck: updatedPps.ppDeck,
                  step3Market: updatedPps.step3Market,
                },
                { merge: true }
              )
              .then(() => {
                // see if this is the last player to buy powerplant, if so, move to the next step
                if (tools.lastPlayerToBuyPlant(data, user)) {
                  // check to see if we should move to step 3
                  let newStage = data.stage;
                  let newCurrentMarket = updatedPps.currentMarket;
                  let newFutureMarket = updatedPps.futureMarket;
                  let newPpDeck = updatedPps.ppDeck;
                  let newStep3Market = updatedPps.step3Market;
                  let newPlayerOrder = data.playerOrder;

                  if (data.stage !== 3 && data.ppDeck.length == 0) {
                    alert("Rearranging partnerships for Step 3");
                    newStage = 3;
                    let newState = tools.setupStep3Pps(data);
                    newCurrentMarket = newState.currentMarket;
                    newFutureMarket = newState.futureMarket;
                    newPpDeck = newState.ppDeck;
                    console.log("UPDATED PPS");
                    console.log(updatedPps);
                    console.log("NEW STATE");
                    console.log(newState);
                  }

                  // if this is the first round of the game, re-arrange player order
                  if (data.round == 1) {
                    let newData = _.cloneDeep(data);
                    newData.playerData[user].pps = pps;
                    newPlayerOrder = tools.updatePlayerOrder(newData);
                  }

                  dbGame.set(
                    {
                      step: "resources",
                      stage: newStage,
                      currentMarket: newCurrentMarket,
                      futureMarket: newFutureMarket,
                      ppDeck: newPpDeck,
                      step3Market: newStep3Market,
                      playerOrder: newPlayerOrder,
                    },
                    { merge: true }
                  );
                }
                // reset state
                setSelectedPp("");
                setBuyPrice(0);
              });
          });
        } else {
          alert(
            "You already have 3 contracts. Go to the '吃飯' tab and destroy one first."
          );
        }
      } else {
        alert("you don't have enough money");
      }
    } else {
      alert("select a partnership / enter a buy price");
    }
  };

  return (
    <Row>
      <Col>
        <div className="powerplants">
          <div className="market">
            <h2>Current Market</h2>
            {data.currentMarket.map((plant, index) => {
              return (
                <Plant key={index} handleSelect={handleSelect} data={plant} />
              );
            })}
          </div>
          <div className="market futureMarket">
            <h2>Future Market</h2>
            {data.futureMarket.map((plant, index) => {
              return (
                <Plant
                  key={index}
                  disabled
                  handleSelect={handleSelect}
                  data={plant}
                />
              );
            })}
          </div>
          <div className="market">
            <h2>Deck</h2>
            <div>{data.step3Market.length} partnerships stored for Step 3</div>
            <div>
              {data.ppDeck[0] < 16 && "Next partnership is low-value"}
              {data.ppDeck[0] > 15 && "Next partnership is high-value"}
            </div>
            <br />
            <h2>Other rules:</h2>
            <ul>
              <li>
                If no one signs a new partnership, the lowest $ partnership will
                be removed.
              </li>
              <li>
                In Step 1 and 2, the highest partnership is removed at the
                beginning of each round.
              </li>
              <li>
                In Step 3, the lowest partnership is removed at the beginning of
                each round.
              </li>
              <li>
                Any partnership with a $ lower than the highest number of
                locations owned will be removed at the end of location buying.
              </li>
            </ul>
          </div>
        </div>

        {data.step == "bidP" && (
          <div className="biddingArea">
            {!data.playerData[props.user].rounds[data.round]?.ppComplete &&
              data.step == "bidP" && (
                <div>
                  <div className="selectedPp">
                    {selectedPp && <Plant disabled data={selectedPp} />}
                    {!selectedPp && (
                      <div className="choosePlant">Choose a partner...</div>
                    )}
                  </div>
                  <h2>Sign this partnership for...</h2>
                  <div className="price">
                    NT$
                    <input
                      className="buyPrice"
                      placeholder="0"
                      onChange={handleMoneyInput}
                      value={buyPrice}
                    ></input>
                  </div>
                  <div className="ctas">
                    <div className="button primary" onClick={handleBuy}>
                      Buy
                    </div>
                    {data.round > 1 && (
                      <div className="button secondary" onClick={handleSkip}>
                        Don't buy any
                      </div>
                    )}
                  </div>
                </div>
              )}
            {data.playerData[props.user].rounds[data.round]?.ppComplete &&
              data.step == "bidP" &&
              "You've already purchased a partnership (or decided to not buy any)."}
          </div>
        )}
      </Col>
    </Row>
  );
};

export default PowerPlants;
