import React, { useState, useEffect } from "react";
import Map from "./Map";
import Setup from "../Components/Setup";
import ActiveGame from "../Components/ActiveGame";
import _ from "lodash";
import { useParams } from "react-router-dom";

const Game = (props) => {
  const [gameData, setGameData] = useState({});
  const [user, setUser] = useState("");
  let { gameId } = useParams();

  const getGameData = async () => {
    const gameRef = props.db.collection("games").doc(gameId);
    const observer = gameRef.onSnapshot(
      (doc) => {
        let gameDetails = doc.data();
        gameDetails.id = doc.id;
        setGameData(gameDetails);
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };

  useEffect(() => {
    getGameData();
  }, []);

  const view = () => {
    if (gameData.mode == "setup") {
      return (
        <Setup db={props.db} data={gameData} user={user} setUser={setUser} />
      );
    } else if (gameData.mode == "active") {
      if (user) {
        return <ActiveGame db={props.db} data={gameData} user={user} />;
      } else {
        return (
          <Setup db={props.db} data={gameData} user={user} setUser={setUser} />
        );
      }
    } else {
      return "loading";
    }
  };

  return <div>{view()}</div>;
};

export default Game;
