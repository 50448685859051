import React, { useState } from "react";
import iconMeat from "../assets/images/icon-meat.png";
import iconChoi from "../assets/images/icon-choi.png";
import iconTea from "../assets/images/icon-tea.png";
import iconTofu from "../assets/images/icon-tofu.png";
import iconLocation from "../assets/images/gate.png";
import iconMixed from "../assets/images/icon-mixed.png";
import iconGate from "../assets/images/gate.png";

import * as config from "../config";

const PlayerIcon = (props) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="playerProfile">
      <div
        className={props.iconClass}
        onMouseEnter={() => {
          setShowDetails(true);
          props.zIndex(true);
        }}
        onMouseLeave={() => {
          setShowDetails(false);
          props.zIndex(false);
        }}
      >
        <div className={"icon icon" + props.player.color} />
        {props.player.name}
        <div className="smallLocations">{props.player.cities.length}</div>
      </div>
      {showDetails && (
        <div>
          <div className={"playerDetails " + props.player.color}>
            <div className="resourcesDetails">
              <div className="resource">
                <img src={iconLocation} /> {props.player.cities.length}
              </div>
              <div className="resource">
                <div className="coal">
                  <img src={iconMeat} /> {props.player.resources.coal}{" "}
                </div>
              </div>
              <div className="resource">
                <div className="oil">
                  <img src={iconChoi} /> {props.player.resources.oil}{" "}
                </div>
              </div>
              <div className="resource">
                <div className="garbage">
                  <img src={iconTea} /> {props.player.resources.garbage}{" "}
                </div>
              </div>
              <div className="resource">
                <div className="uranium">
                  <img src={iconTofu} /> {props.player.resources.uranium}{" "}
                </div>
              </div>
              {props.player.pps.map((plant) => {
                let type = config.powerPlants[plant].type;
                let typeIcon;
                if (type == "coal") {
                  typeIcon = iconMeat;
                } else if (type == "oil") {
                  typeIcon = iconChoi;
                } else if (type == "hybrid") {
                  typeIcon = iconMixed;
                } else if (type == "garbage") {
                  typeIcon = iconTea;
                } else if (type == "uranium") {
                  typeIcon = iconTofu;
                }
                return (
                  <div className="plantMini">
                    <div className="topbar">
                      <div className="cost">
                        ${config.powerPlants[plant].cost}
                      </div>
                      <div className="resourceContainer">
                        {[
                          ...Array(config.powerPlants[plant].resourcesNeeded),
                        ].map((e, i) => (
                          <img
                            className="resourceIcons"
                            key={i}
                            src={typeIcon}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="locations">
                      <img src={iconGate} />{" "}
                      {config.powerPlants[plant].citiesPowered}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlayerIcon;
