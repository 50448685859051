import firebase from "firebase";
var firebaseConfig = {
  apiKey: "AIzaSyBrJYJd41MA0DY51D4Fu9AcYeJHeTnfJVg",
  authDomain: "powergrid-d7828.firebaseapp.com",
  projectId: "powergrid-d7828",
  storageBucket: "powergrid-d7828.appspot.com",
  messagingSenderId: "502371966071",
  appId: "1:502371966071:web:47b2356be0da3b88b13a72",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase;
