import React from "react";

const City = (props) => {
  // render city position
  const cssTop = props.top * 100 + "%";
  const cssLeft = props.left * 100 + "%";
  const positionStyle = {
    top: cssTop,
    left: cssLeft,
  };

  const data = props.data;

  const owners = () => {
    let owners = [];
    let playerDataKeys = Object.keys(data.playerData);
    playerDataKeys.forEach((player) => {
      if (data.playerData[player]?.cities?.includes(props.id)) {
        owners.push(data.playerData[player].color);
      }
    });
    return owners;
  };

  let disabledClass = "";
  if (props.disabled) {
    disabledClass = "disabledCity";
  }

  return (
    <div className={disabledClass}>
      <div
        className="cityContainer"
        style={positionStyle}
        id={"ID" + props.id}
        onMouseEnter={() => {
          if (!props.disabled) {
            props.handleSelect(props.id);
          }
        }}
        onClick={() => {
          if (!props.disabled) {
            props.handleClick(props.id);
          }
        }}
      >
        <div className="homes">
          {owners().map((owner, index) => {
            return <div key={index} className={"house" + owner}></div>;
          })}
        </div>
        <div className="cityName">
          <div>{props.name}</div>
          <div className="chinese">{props.chinese}</div>
        </div>
      </div>
    </div>
  );
};

export default City;
