import React, { Fragment, useState, useEffect } from "react";
import firebase from "../Firebase/Firestore";
import * as tools from "../functions";
import * as config from "../config";
import Map from "../Pages/Map";
import _ from "lodash";
import { Row, Col, Tabs, Tab } from "react-bootstrap";

const Resources = (props) => {
  const [activeUser, setActiveUser] = useState("");
  const data = props.data;
  const user = props.user;
  const dbGame = props.db.collection("games").doc(data.id);
  const currentUserData = data.playerData[user];

  const handleBuy = (resource) => {
    tools.buyResource(data, user, currentUserData, dbGame, resource);
  };

  const handleDoneShopping = () => {
    let pathRound =
      "playerData." + user + ".rounds." + data.round + ".resourcesComplete";
    let obj = {};
    obj[pathRound] = true;
    obj["currentPlayer"] = tools.whoseTurn(data, "resourcesComplete");
    dbGame.update(obj).then(() => {
      // see if this is the last player to buy powerplant, if so, move to the next step
      if (tools.lastPlayerToBuyResources(data, user)) {
        dbGame.set(
          {
            step: "map",
          },
          { merge: true }
        );
      }
    });
  };

  useEffect(() => {
    setActiveUser(tools.whoseTurn(data, "resourcesComplete"));
  }, [data]);

  let blankCoal = 24 - data.resources.coal.length;
  let blankOil = 24 - data.resources.oil.length;
  let blankGarbage = 24 - data.resources.garbage.length;
  let blankUranium = 12 - data.resources.uranium.length;

  let coalSupply = 24 - data.resources.coal.length;
  let oilSupply = 24 - data.resources.oil.length;
  let garbageSupply = 24 - data.resources.garbage.length;
  let uraniumSupply = 12 - data.resources.uranium.length;

  let playersArray = Object.keys(data.playerData);
  playersArray.forEach((player) => {
    coalSupply = coalSupply - data.playerData[player].resources.coal;
    oilSupply = oilSupply - data.playerData[player].resources.oil;
    garbageSupply = garbageSupply - data.playerData[player].resources.garbage;
    uraniumSupply = uraniumSupply - data.playerData[player].resources.uranium;
  });

  return (
    <Fragment>
      <Row>
        <Col lg={9}>
          <div className="resources">
            <div className="resource">
              <div>
                {activeUser == user && data.step == "resources" && (
                  <button
                    onClick={() => {
                      handleBuy("coal");
                    }}
                  >
                    Buy
                  </button>
                )}
              </div>
              {data.resources.coal.map((plant, index) => {
                return (
                  <div className="coal" key={index}>
                    <div className="price">${plant}</div>
                  </div>
                );
              })}
              {[...Array(blankCoal)].map((e, i) => (
                <div className="blank" key={i}></div>
              ))}
              <div className="remainingSupply">
                <div className="title">{coalSupply}</div>
                <div className="subtitle">Left in Taipei</div>
              </div>
            </div>
            <div className="resource">
              <div>
                {activeUser == user && data.step == "resources" && (
                  <button
                    onClick={() => {
                      handleBuy("oil");
                    }}
                  >
                    Buy
                  </button>
                )}
              </div>
              {data.resources.oil.map((plant, index) => {
                return (
                  <div className="oil" key={index}>
                    <div className="price">${plant}</div>
                  </div>
                );
              })}
              {[...Array(blankOil)].map((e, i) => (
                <div className="blank" key={i}></div>
              ))}
              <div className="remainingSupply">
                <div className="title">{oilSupply}</div>
                <div className="subtitle">Left in Taipei</div>
              </div>
            </div>
            <div className="resource">
              <div>
                {activeUser == user && data.step == "resources" && (
                  <button
                    onClick={() => {
                      handleBuy("garbage");
                    }}
                  >
                    Buy
                  </button>
                )}
              </div>
              {data.resources.garbage.map((plant, index) => {
                return (
                  <div className="garbage" key={index}>
                    <div className="price">${plant}</div>
                  </div>
                );
              })}
              {[...Array(blankGarbage)].map((e, i) => (
                <div className="blank" key={i}></div>
              ))}
              <div className="remainingSupply">
                <div className="title">{garbageSupply}</div>
                <div className="subtitle">Left in Taipei</div>
              </div>
            </div>
            <div className="resource">
              <div>
                {activeUser == user && data.step == "resources" && (
                  <button
                    onClick={() => {
                      handleBuy("uranium");
                    }}
                  >
                    Buy
                  </button>
                )}
              </div>
              {data.resources.uranium.map((plant, index) => {
                return (
                  <div className="uranium" key={index}>
                    <div className="price">${plant}</div>
                  </div>
                );
              })}
              {[...Array(blankUranium)].map((e, i) => (
                <div className="blank" key={i}></div>
              ))}
              <div className="remainingSupply">
                <div className="title">{uraniumSupply}</div>
                <div className="subtitle">Left in Taipei</div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={3} className="resources">
          {activeUser == user && data.step == "resources" && (
            <button
              onClick={() => {
                handleDoneShopping();
              }}
            >
              I'm done shopping
            </button>
          )}
          {activeUser !== user && data.step == "resources" && (
            <div className="waiting">Waiting on {activeUser}...</div>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="resources">
          <h4>Ingredients Replenishing Schedule</h4>
          <table className="resourcesTable resource">
            <thead>
              <tr>
                <th></th>
                <th>Step 1</th>
                <th>Step 2</th>
                <th>Step 3</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="coal"></div>
                </td>
                <td>+{config.resourceUpdate[data.numberOfPlayers][1].coal}</td>
                <td>+{config.resourceUpdate[data.numberOfPlayers][2].coal}</td>
                <td>+{config.resourceUpdate[data.numberOfPlayers][3].coal}</td>
              </tr>
              <tr>
                <td>
                  <div className="oil"></div>
                </td>
                <td>+{config.resourceUpdate[data.numberOfPlayers][1].oil}</td>
                <td>+{config.resourceUpdate[data.numberOfPlayers][2].oil}</td>
                <td>+{config.resourceUpdate[data.numberOfPlayers][3].oil}</td>
              </tr>
              <tr>
                <td>
                  <div className="garbage"></div>
                </td>
                <td>
                  +{config.resourceUpdate[data.numberOfPlayers][1].garbage}
                </td>
                <td>
                  +{config.resourceUpdate[data.numberOfPlayers][2].garbage}
                </td>
                <td>
                  +{config.resourceUpdate[data.numberOfPlayers][3].garbage}
                </td>
              </tr>
              <tr>
                <td>
                  <div className="uranium"></div>
                </td>
                <td>
                  +{config.resourceUpdate[data.numberOfPlayers][1].uranium}
                </td>
                <td>
                  +{config.resourceUpdate[data.numberOfPlayers][2].uranium}
                </td>
                <td>
                  +{config.resourceUpdate[data.numberOfPlayers][3].uranium}
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Resources;
